import React, {ForwardedRef, forwardRef, useCallback, useImperativeHandle, useReducer} from 'react';
import LoadingIndicator from "./LoadingIndicator";
import ErrorModal from "./ErrorModal";
import {DEBUG_CONSOLE, ERROR_CODE, HTTP_STATUS_CODE, LOGIN_URL} from "./Constants";
import {useNavigate} from "react-router-dom";

/**
 *  @summary
 *  back-end 통신 관련 공통 처리(통신, 에러 팝업, 처리 alert) layout
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 영역
/*################################################################################*/
/**
 *  @constant
 *  @type {Object}
 *  @description  상태 정보
 */
const STATE_CODE = {
  LOADING: 'LOADING',     // 로딩 상태
  SUCCESS: 'SUCCESS',     // 응답 받은 상태
  ERROR: 'ERROR',     // 에러 상태
  EXPIRED: 'EXPIRED',     // token expired
  ERROR_CHECK: 'ERROR_CHECK',     // 에러를 체크한 경우
};

/**
 *  @constant
 *  @type {Object}
 *  @description  데이터 처리 요청 구분 command
 *         DATA_LIST   :  데이터 리스트 요청
 *         DATA_INFO   :  데이터 정보 요청
 *         DATA_CREATE :  데이터 생성 요청
 *         DATA_UPDATE :  데이터 변경 요청
 *         DATA_DELETE :  데이터 삭제 요청
 */
export const COMMAND = {
  DATA_LIST: 'DATA_LIST',
  DATA_INFO: 'DATA_INFO',
  DATA_CREATE: 'DATA_CREATE',
  DATA_UPDATE: 'DATA_UPDATE',
  DATA_DELETE: 'DATA_DELETE',
};

/**
 *  @interface
 *  @type {Object}
 *  @description  해당 컴포넌트 사용 속성
 */
interface NetworkProps {
  process: any,
  response: any,
  history: any,
  onErrorCheck?: any
}

/**
 *  @type      {function(*): *}
 *  @function  상태 처리하는 reducer
 *  @param     {Object}  state   - 상태 값
 *  @param     {Object}  action  - 처리에 필요한 데이터 들(command, params)
 *  @return    {Object}  응답 state
 */
function reducer(state: any, action: any) {
  switch (action.type) {

    // 로딩 상태 일 경우
    case STATE_CODE.LOADING:
      return {
        loading: true,
        data: null,
        error: null,
        action: null,
        errorCode: ERROR_CODE.NO_ERROR,
      };

    // 응답을 받은 경우
    case STATE_CODE.SUCCESS:
      return {
        loading: false,
        data: action.data,
        error: null,
        errorCode: ERROR_CODE.NO_ERROR,
        action: {command: action.command, params: action.params}
      };

    // 에러가 발생한 경우
    case STATE_CODE.ERROR:
      return {
        loading: false,
        data: null,
        error: action.error,
        // errorCode : ERROR_CODE.NO_DATA ,
        errorCode: action.errorCode,
        action: {command: action.command, params: action.params}
      };

    // token expired 된 경우
    case STATE_CODE.EXPIRED:
      return {
        loading: false,
        data: null,
        error: action.error,
        errorCode: ERROR_CODE.TOKEN_EXPIRED,
        action: {command: action.command, params: action.params}
      };

    // 에러를 확인 한 경우
    case STATE_CODE.ERROR_CHECK:
      return {
        ...state,
        error: null,
        errorCode: ERROR_CODE.NO_ERROR,
        action: null,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

/**
 *  @type      {React.ComponentType<{} & React.ClassAttributes<unknown>>}
 *  @function  network 처리를 할 수 있는 함수형 컴포넌트
 *  @param     {Object} props - 상위 컴포넌트에서 전달 받은 property
 */
const NetworkLayout = forwardRef((props: NetworkProps, ref: ForwardedRef<any>) => {
  const navigate = useNavigate();

  /*################################################################################*/
  //## data 영역
  //##  - props, state
  /*################################################################################*/
  /*
  *   상위 컴포넌트에서 전달 받은 props
  *   1. process      : back-end 에 요청 처리 하는 로직 함수
  *   2. response     : 응답을 전달하기 위한 함수
  *   3. history      : 페이지 이동을 위한 history 객체
  *   4. onErrorCheck : 에러 확인시 처리 함수
  */
  const {process, response, history, onErrorCheck = undefined} = props;

  /*
  *   back-end 통신 관련 data
  *   1. loading    : 로딩 상태 여부
  *   2. data       : 통신 응답 데이터
  *   3. error      : 에러 내용
  *   4. action     : 전송시 사용했던 정보 (command, params)
  *   5. errorCode  : 에러 코드
  */
  const [state, dispatch] = useReducer(reducer, {
    loading: false,
    data: null,
    error: null,
    action: null,
    errorCode: 0,
  });

  const {loading, error, errorCode} = state;

  /*################################################################################*/
  //## function define 영역
  //## - useCallback
  /*################################################################################*/
  /**
   *  @function  processData
   *  @param  {String} command  - 데이터 처리 함수에게 전달할 처리 구분 코드
   *  @param  {Object} params   - 데이터 처리 함수에게 전달할 parameter
   *  @description  데이터 통신 처리 framework
   */
  const processData = useCallback(async (command: string, params: any) => {
    dispatch({type: STATE_CODE.LOADING});
    try {
      if (DEBUG_CONSOLE) {
        console.log(`request command : ${command}`);
        console.log(params);
      }

      let data = null;

      /**
       * 1. props로 전달받은 process(dataProcess)가 있을 경우
       *   1) dataProcess에 요청할 command 내용 존재 : process 그대로 실행
       *   2) dataProcess에 요청할 command 내용 X   : 공통 모듈 command 실행
       * 2. props로 전달받은 process(dataProcess)가 없을 경우
       *     : 공통 모듈 command 실행
       */
      // process 있을 시,
      if (process !== undefined) {
        // 데이터 처리 요청
        data = await process(command, params);
      }

      // 응답코드가 음수인 경우 error
      if (data.code !== HTTP_STATUS_CODE.SUCCESS && command !== 'DOWNLOAD') {
        const errorString = data.payload.message;

        dispatch({
          type: STATE_CODE.ERROR,
          error: errorString,
          errorCode: data.code,
          command,
          params,
        });
      } else {
        dispatch({type: STATE_CODE.SUCCESS, data, command, params});

        // 응답 데이터
        const actionData = {command: command, params: params};
        response(actionData, data);
      }
    } catch (e: any) {
      if (e['response'] && e['response']['data'] && e['response']['data']['code'] === 401 && e['response']['data']['payload']['message'] === "Unauthorized") { // access token이 만료되었을 때, refresh api 호출
        console.log(e)
        const params = {
          requestUrl: 'https://api-dashboard.xyzcorp.io/auth/refresh',
          sendString: {},
        };
        await processData(COMMAND.DATA_UPDATE, params);
      }
      if (e['response'] && e['response']['data'] && e['response']['data']['code'] === 401) {       // refresh token이 만료되었을 때, 로그인창으로 이동
        dispatch({type: STATE_CODE.EXPIRED, error: e.response.data.payload.message, command, params});
      } else {
        dispatch({type: STATE_CODE.ERROR, error: 'error', command, params});
      }
    }
  }, [process, response]);


  /**
   *  @function   errorCheck
   *  @param      {number} code  - 에러코드
   *  @description  에러 확인 버튼을 클릭시 처리
   */
  const errorCheck = useCallback((code: number = ERROR_CODE.NO_ERROR) => {

    switch (code) {
      case ERROR_CODE.AUTH_ERROR:
      case ERROR_CODE.NO_TOKEN:
      case ERROR_CODE.TOKEN_EXPIRED:
      case ERROR_CODE.VERIFY_FAIL:
        navigate(LOGIN_URL);
        break;

      case ERROR_CODE.NO_USER:
        dispatch({type: STATE_CODE.ERROR_CHECK});
        break;

      default:
        dispatch({type: STATE_CODE.ERROR_CHECK});
        break;
    }
  }, [history]);


  /**
   *  @function  handleErrorRetry
   *  @param  none
   *  @description  에러창에서 재시도(retry) 버튼 클릭 시 처리
   */
  const handleErrorRetry = useCallback(() => {

    // action state 에서 이전 호출했던 정보 get
    const {command, params} = state.action;

    // back-end 데이터 처리 요청
    processData(command, params).then();

  }, [processData, state.action]);

  /**
   *  @function  handleErrorClose
   *  @param  {number}  code - 에러코드
   *  @description  에러창에서 닫기 버튼 클릭 시 처리
   */
  const handleErrorClose = useCallback((code: number | undefined) => {
    // error 확인 처리 요청
    errorCheck(code);

    if (onErrorCheck !== undefined) {
      const {command, params} = state.action;
      onErrorCheck(code, command, params);
    }

  }, [errorCheck, onErrorCheck, state.action]);

  /**
   *  @function  request
   *  @param  {String} command  - 데이터 처리 함수에게 전달할 처리 구분 코드
   *  @param  {Object} params   - 데이터 처리 함수에게 전달할 parameter
   *  @description  일반 컴포넌트에서 네트워크 요청 처리를 하는 함수
   */
  useImperativeHandle(ref, () => ({
    request(command: any, params: any) {
      processData(command, params).then();
    },
  }));

  /*################################################################################*/
  //## component view 영역
  //## - JSX return
  /*################################################################################*/
  return (
    <>
      {/* loading indicator */}
      {window.location.pathname === '/aris' || window.location.pathname === '/baris' ?
        null
        : <LoadingIndicator loading={loading}/>}


      {/* error popup */}
      {(errorCode !== ERROR_CODE.NO_ERROR) &&
        <ErrorModal onRetry={handleErrorRetry}
                    onClose={handleErrorClose}
                    errorInfo={error}
                    errorCode={errorCode}
                    history={history}
        />
      }

    </>
  );
});

export default React.memo(NetworkLayout);
