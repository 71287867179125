import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';

// Components
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_DELETE, AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../common/Network/CommonFunction";
import {MenuDetail} from "../components/Detail/MenuDetail";
import {SettingMenu} from "../components/SettingMenu";
import {SettingUserGroup} from "../components/SettingUserGroup";
import {SettingUser} from "../components/SettingUser";

// Common
import {handleState, handleListState} from "../components/common/common";
import {Button, Table} from "react-bootstrap";
import {RobotDetail} from "../components/Detail/RobotDetail";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;

      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 삭제 요청
    case COMMAND.DATA_DELETE : {
      const {requestUrl} = params;
      response = await AXIOS_DELETE(requestUrl);
    }
      break;
    default:
      return null;
  }

  return response.data;
}

export const Settings: React.FC = (props: any) => {
  const [tab, setTab] = useState(0);
  const [menuList, setMenuList] = useState([]);
  const [menuDetail, setMenuDetail] = useState([]);
  const [selected, setSelected] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(0);


  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback(async (action: any, data: any) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setMenuList(data.payload);
      }
      if (command === COMMAND.DATA_INFO) {
        setMenuDetail(data.payload.menu);
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  const searchDetail = (data:string) => {
    const command = COMMAND.DATA_INFO;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu/' + data
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }


  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);


  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'settingsContainer'}>
        <div className={'settingsTab'}>
          <div className={tab === 0 ? 'tab on' : 'tab'} onClick={() => setTab(0)}>메뉴</div>
          <div className={tab === 1 ? 'tab on' : 'tab'} onClick={() => setTab(1)}>사용자 그룹</div>
          <div className={tab === 2 ? 'tab on' : 'tab'} onClick={() => setTab(2)}>사용자</div>
        </div>
        {tab === 0 ? <SettingMenu windowWidth={windowWidth} /> : null}
        {tab === 1 ? <SettingUserGroup windowWidth={windowWidth} /> : null}
        {tab === 2 ? <SettingUser windowWidth={windowWidth} /> : null}

      </div>
    </>
  );
};
