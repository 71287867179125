import React, {useCallback, useEffect, useRef, useState} from "react";
import {AXIOS_GET, AXIOS_POST} from "../../common/Network/CommonFunction";
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";

// Component
import {MenuMobile} from '../Mobile/Menu'

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any) : Promise<any> {

  let response = null ;

  switch (command) {

    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST :{
        const {requestUrl} = params;
        response = await AXIOS_GET(requestUrl);
      }
      break;
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Header: React.FC = (props:any) => {
  const [detail, setDetail] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);

  /**
    *   상위 컴포넌트에서 전달 받은 props
    *   1. history    : URL 이동을 위해 사용
    */
  const { history } = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent                  = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any)=>{
    // data 가 있는 경우
    if(data){
      // action state 에서 이전 호출했던 정보 get
      const { command } = action ;

      // 응답에 대한 처리
      if( command === COMMAND.DATA_LIST) {
        setDetail(data.payload);
      }
      if(command === COMMAND.DATA_UPDATE){
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  },[]);

  /** back end 통신 example  */
  useEffect(()=>{
    const command = COMMAND.DATA_LIST;

    const params = {
      requestUrl : process.env.REACT_APP_API_HOST + '/setting/employee'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  },[]);

  useEffect(() => {
    if(menuOpen){
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuOpen]);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history} />
      <div className={'headerContainer'}>
        <img className={'menuIcon'} src={'/static/img/dashboard/icon-menu.png'} alt={'icon_menu'} onClick={() => {
          setMenuOpen(!menuOpen);
          // document.body.style.overflow = "hidden";
        }}/>
        <div>
          <span>{detail['name'] || ''}</span> 님, 안녕하세요!
        </div>
      </div>
      {menuOpen ? <MenuMobile setMenu={setMenuOpen} /> : null}
    </>
  );
};
