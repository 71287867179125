import React, {useEffect, useState} from "react";
import Datepicker from 'react-datepicker';

// CSS
import './index.scss'
import "react-datepicker/src/stylesheets/datepicker.scss";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

export const DatePicker = ({...props}) => {
  // const [date, setDate] = useState(new Date());


  return (
    <Datepicker
      // locale={'ko'}    // 언어설정 기본값은 영어
      dateFormat="yyyy-MM-dd"    // 날짜 형식 설정
      className={props.period !== 'self' ? 'input-datepicker none' : 'input-datepicker'}
      // minDate={new Date()}    // 선택할 수 있는 최소 날짜값 지정
      closeOnScroll={true}    // 스크롤을 움직였을 때 자동으로 닫히도록 설정 기본값 false
      placeholderText="YYYY-MM-DD"    // placeholder
      selected={props.date}    // value
      readOnly={props.period !== 'self'}
      backgroundColor={'red'}
      onChange={(date) => props.handleDate(props.state, date)}    // 날짜를 선택하였을 때 실행될 함수
    />
  );
};
