import React, {useEffect, useState, useCallback, useRef} from "react";

// CSS
import '../index.scss'
import {Form, Row} from "react-bootstrap";

// Common
import {handleDateFormat} from "../common/moment";

// Network
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../../common/Network/CommonFunction";
import {Col} from "reactstrap";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 생성 요청
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const MenuDetail = ({...props}) => {
  const {history} = props;
  const [detail, setDetail] = useState([]);

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        setDetail(data.payload.menu);
      }
      if (command === COMMAND.DATA_CREATE || command === COMMAND.DATA_UPDATE) {
        // alert('저장되었습니다.');
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (props.menuId !== 'new') {
      const command = COMMAND.DATA_INFO;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu/' + props.menuId
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      setDetail(
        {
          'branch_id': '',
          'brand_nm': '',
          'branch_nm_ko': '',
          'branch_nm_en': '',
        });
    }
  }, [props.menuId]);

  const handleSave = () => {
    const command = COMMAND.DATA_CREATE;
    let postParams = {
      "name": detail['name'],
      "description": detail['description'],
      "path": detail['path'],
      "api_prefix": detail['api_prefix'],
      "order_no": Number(detail['order_no']),
      "active_yn": Number(detail['active_yn'])
    };

    const params = {
      sendString: postParams,
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleUpdate = () => {
    const command = COMMAND.DATA_UPDATE;

    let postParams = {
      "name": detail['name'],
      "description": detail['description'],
      "path": detail['path'],
      "api_prefix": detail['api_prefix'],
      "order_no": Number(detail['order_no']),
      "active_yn": Number(detail['active_yn'])
    };

    const params = {
      sendString: postParams,
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu/' + props.menuId
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleState = (state, value) => {
    let tempState = JSON.parse(JSON.stringify(detail));
    tempState[state] = value;
    setDetail(tempState);
  }


  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"subTitleItem"}>상세 정보</div>
      <Form noValidate>

        {props.menuId === 'new' ? '' :         <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>메뉴 ID</Form.Label>
            <Form.Control
              required
              readOnly={props.menuId !== 'new'}
              type="text"
              placeholder="메뉴 아이디"
              onChange={(e) => handleState('menu_id', e.target.value)}
              value={(detail && detail['menu_id']) || ''}
            />
          </Form.Group>
        </Row>}
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>메뉴명</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="메뉴명"
              onChange={(e) => handleState('name', e.target.value)}
              value={(detail && detail['name']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>설명</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="설명"
              onChange={(e) => handleState('description', e.target.value)}
              value={(detail && detail['description']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>라우팅</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="라우팅"
              onChange={(e) => handleState('path', e.target.value)}
              value={(detail && detail['path']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>Prefix</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Prefix"
              onChange={(e) => handleState('api_prefix', e.target.value)}
              value={(detail && detail['api_prefix']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>메뉴 순서</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="메뉴 순서"
              onChange={(e) => handleState('order_no', e.target.value)}
              value={(detail && detail['order_no']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>활성화</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 1)}
                            checked={detail && detail['active_yn'] === 1}/>활성화</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 0)}
                            checked={detail && detail['active_yn'] === 0}/>비활성화</label>
            </div>
          </Form.Group>
        </Row>
        {props.menuId !== 'new' ?
          <>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>최초 생성 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 생성 아이디"
                  value={(detail && detail['crt_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>최초 생성 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['crt_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>마지막 수정 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 아이디"
                  value={(detail && detail['upd_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>마지막 수정 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['upd_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
          </>
          : null}
      </Form>

      <div className={"buttons"}>
        {props.menuId === 'new' ? <div className={'save_btn'} onClick={() => handleSave()}>저장</div> : <div className={'save_btn'} onClick={() => handleUpdate()}>수정</div> }
      </div>
    </>
  );
};
