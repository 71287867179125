// KAKAO KEY
export const KAKAO_KEY = '19af1bea0aa6976498adb5ebc059e373';

// 3자리 콤마
export const handleNumber = (num) => {
  // return num;
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const handleState = (state, setState, text, value) => {
  let tempState = JSON.parse(JSON.stringify(state));
  tempState[text] = value;
  setState(tempState);
}

export const handleListState = (state, setState, id, stateName, value) => {
  const findIndex = state.findIndex(element => element['menu_id'] === id);

  let tempState = JSON.parse(JSON.stringify(state));
  if(findIndex > -1) {
    if(tempState[findIndex]['rights'] === value) {
      tempState[findIndex] = {...tempState[findIndex], 'rights': 'N'};
    } else {
      tempState[findIndex] = {...tempState[findIndex], 'rights': value};
    }
  }

  setState(tempState);
}

export const colors = {
  'RED': {
    1: '#cc444b',
    2: '#da5552',
    3: '#df7373',
    4: '#e39695',
    5: '#e4b1ab',
  },
  'BLUE': {
    1: '#03045e',
    2: '#0077b6',
    3: '#00b4d8',
    4: '#90e0ef',
    5: '#caf0f8',
  },
  'YELLOW': {
    1: '#e2711d',
    2: '#ff9505',
    3: '#ffb627',
    4: '#ffc971',
    5: '#cc5803',
  },
  'GREEN': {
    1: '#22577a',
    2: '#38a3a5',
    3: '#57cc99',
    4: '#80ed99',
    5: '#c7f9cc',
  },
  'BROWN': {
    1: '#9c6644',
    2: '#7f5539',
    3: '#b08968',
    4: '#ddb892',
    5: '#e6ccb2',
  },
  'PURPLE': {
    1: '#6f2dbd',
    2: '#a663cc',
    3: '#b298dc',
    4: '#b8d0eb',
    5: '#b9faf8',
  },
  'PINK': {
    1: '#f08080',
    2: '#f4978e',
    3: '#f8ad9d',
    4: '#fbc4ab',
    5: '#ffdab9',
  },

}
