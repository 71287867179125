import React, {useCallback, useEffect, useRef, useState} from "react";

// Components
import {DatePicker} from "./DatePicker";

// css
import './css/SearchBox.scss';

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET} from "../common/Network/CommonFunction";
import {handleDateFormat} from "./common/moment";
import {Table} from "react-bootstrap";
import {MenuDetail} from "./Detail/MenuDetail";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const SettingMenu = (props: any) => {
  const [menuList, setMenuList] = useState([]);
  const [selected, setSelected] = useState('');



  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setMenuList(data.payload);

      }
    }
  }, [props]);

  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/menu'
    };

    netWorkAgent.current.request(command, params);
  }, []);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'settingContent'}>
        <div className={'settingContent'}>
          <Table
            className={"robot-table-title"}
            style={{
              textAlign: "center",
              fontFamily: "Spoqa Han Sans Neo",
            }}
          >
            <thead>
            <tr>
              {props.windowWidth < 768 ?
                <>
                  <th>메뉴명</th>
                  <th>설명</th>
                  <th>라우팅</th>
                </> :
                <>
                  <th>메뉴 ID</th>
                  <th>메뉴명</th>
                  <th>설명</th>
                  <th>라우팅</th>
                  <th>활성화</th>
                </>}
            </tr>
            </thead>
            <tbody>
            {(menuList || []).map((menu, i) => {
              return (
                <tr key={"robot_" + i} onClick={() => setSelected(menu['menu_id'])}>
                  {props.windowWidth < 768 ?
                    <>
                      <td>{menu['name']}</td>
                      <td>{menu['description']}</td>
                      <td>{menu['path']}</td>
                    </> : <>
                      <td>{menu['menu_id']}</td>
                      <td>{menu['name']}</td>
                      <td>{menu['description']}</td>
                      <td>{menu['path']}</td>
                      <td>{menu['active_yn'] === 1 ? '활성화' : '비활성화'}</td>
                    </>}
                </tr>
              );
            })}
            </tbody>
          </Table>
        </div>
        <div className={"buttonContainer"}>
          <div className={"saveBtn"} onClick={() => setSelected('new')}>추가</div>
        </div>
        {selected ? <MenuDetail menuId={selected} /> : null}
      </div>
    </>
  );
}
