import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';

// Components
import {Table} from "react-bootstrap";
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";
import {PaginationComponent} from "../components/Pagination";
import {StoreDetail} from "../components/Detail/StoreDetail";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Store: React.FC = (props: any) => {
  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;
  const day = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
  const [tab, setTab] = useState<number>(0);
  const [sort, setSort] = useState('branch_id');
  const [openModal, setOpenModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [clickedBranch, setClickedBranch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  // const [clickedStore, setClickedStore] = useState<string>('');

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {

    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        let tempList = JSON.parse(JSON.stringify(data.payload.list));
        for (let item of tempList) {
          item['is_checked'] = false;
        }
        setStoreList(tempList);
        setTotalCount(Number(data.payload.meta.itemCount));
      }
      if (command === COMMAND.DATA_UPDATE) {
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (page || sort) {
      const command = COMMAND.DATA_LIST;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/branches?take=10&page=' + page + '&orderField=' + sort + '&order=ASC'
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    }

  }, [page, sort])

  const handleUpdate = (data: string) => {
    setClickedBranch(data);
    setDetailModal(true);
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'robotContainer'}>
        <div className={'robotTab'}>
          <div className={tab === 0 ? 'tab on' : 'tab'} onClick={() => setTab(0)}>매장정보</div>
        </div>

        <div className={'robotContent'}>
          <div className={'sortBox'}>
            <div className={sort === 'branch_id' ? 'sortItem on' : 'sortItem'}
                 onClick={() => setSort('branch_id')}>지점아이디순
            </div>
            <div className={'sortOption'}>|</div>
            <div className={sort === 'branch_nm_ko' ? 'sortItem on' : 'sortItem'}
                 onClick={() => setSort('branch_nm_ko')}>지점명순
            </div>
            <div className={'sortOption'}>|</div>
            <div className={sort === 'crt_dttm' ? 'sortItem on' : 'sortItem'} onClick={() => setSort('crt_dttm')}>최신순
            </div>
          </div>

          <Table striped className={"robot-table-title"} style={{textAlign: "center", fontFamily: "Spoqa Han Sans Neo",}}>
            <thead>
            <tr>
              {windowWidth < 768 ?
                <>
                  <th>지점아이디</th>
                  <th>지점명</th>
                </>
                :
                <>
                  <th>지점아이디</th>
                  <th>지점명</th>
                  <th>주소</th>
                  <th>활성화여부</th>
                  <th>노출여부</th>
                  <th>최초오픈일</th>
                  <th>폐업일</th>
                  <th>휴무일</th>
                  <th>설치된 로봇 수</th>
                </>
              }
            </tr>
            </thead>
            <tbody>
            {(storeList || []).map((store, i) => {
              return (
                <tr key={"robot_" + i} onClick={(e) => {
                  handleUpdate(store['branch_id']);
                }}>
                  {windowWidth < 768 ?
                    <>
                      <td>{store['branch_id']}</td>
                      <td>{store['branch_nm_ko']}</td>
                    </>
                    :
                    <>
                      <td>{store['branch_id']}</td>
                      <td>{store['branch_nm_ko']}</td>
                      <td>{store['address']}</td>
                      <td>{store['active_yn'] === 0 ? 'X' : 'O'}</td>
                      <td>{store['visible'] === 0 ? 'X' : 'O'}</td>
                      <td>{store['open_date']}</td>
                      <td>{store['close_date']}</td>
                      <td>
                        {(day).map((item, index) => {
                          return (
                            <span key={index}>{store[item] === 0 ? item + ' ' : null}</span>
                          )
                        })}

                      </td>
                      <td>{store['aris_count'] ? '아리스' + store['aris_count'] : null} {store['baris_count'] ? '바리스' + store['baris_count'] : null}</td>
                    </>
                  }
                </tr>
              );
            })}
            </tbody>
          </Table>
        </div>
        <div className={"buttonContainer"}>
          <div className={"saveBtn"} onClick={() => setClickedBranch('new')}>추가</div>
        </div>
        <PaginationComponent totalCount={totalCount} page={page} setPage={setPage}/>
        {clickedBranch ? <StoreDetail branchId={clickedBranch}/> : null}
      </div>
    </>
  );
};
