import React, {useEffect, useState, useCallback, useRef} from "react";
import {Form, Row} from "react-bootstrap";

// CSS
import '../index.scss'

// Common
import {handleDateFormat} from "../common/moment";

// Network
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../../common/Network/CommonFunction";
import {Col} from "reactstrap";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 생성 요청
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const ReviewDetail = ({...props}) => {
  const {history} = props;
  const [detail, setDetail] = useState([]);

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        setDetail(data.payload);
      }
      if (command === COMMAND.DATA_CREATE || command === COMMAND.DATA_UPDATE) {
        // alert('저장되었습니다.');
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (props.reviewId !== 'new') {
      const command = COMMAND.DATA_INFO;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/reviews/' + props.reviewId['branch_id'] +'/review/' + props.reviewId['ord_no']
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    }
  }, [props.reviewId]);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"subTitleItem"}>상세 정보</div>
      <Form noValidate>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>지점명</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="지점명"
              value={(detail && detail['branch_nm_ko']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>소요시간</Form.Label>
            <Form.Control
              disabled={true}
              type="text"
              placeholder="소요시간"
              value={(detail && detail['operate_time']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>주문번호</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="주문번호"
              value={(detail && detail['ord_no']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>좌석번호</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="좌석번호"
              value={(detail && detail['seat_no']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>등록일자</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="등록일자"
              value={(detail && detail['reg_dt'] && detail['reg_dt'].slice(0, 4) + '-' + detail['reg_dt'].slice(4, 6) + '-' + detail['reg_dt'].slice(6, 8)) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>등록시간</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="등록시간"
              value={(detail && detail['reg_tm'] && detail['reg_tm'].slice(0, 2) + ':' + detail['reg_dt'].slice(2, 4)) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>추천점수</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="추천점수"
              value={(detail && detail['recommend_rating']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom01">
            <Form.Label>맛점수</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="맛점수"
              value={(detail && detail['taste_rating']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="4" controlId="validationCustom02">
            <Form.Label>속도점수</Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="속도점수"
              value={(detail && detail['speed_rating']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom02">
            <Form.Label>주문 목록 ={">"} <span style={{'color': 'blue'}}>{((detail && detail['qty'] )|| '')} 개</span></Form.Label>
            <Form.Control
              disabled
              type="text"
              placeholder="등록시간"
              value={(detail && detail['purchase_items']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom02">
            <Form.Label>코멘트</Form.Label>
            <Form.Control
              as="textarea"
              disabled
              type="text"
              placeholder="코멘트"
              style={{height: '100px', resize:'none'}}
              value={(detail && detail['comment']) || ' '}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};
