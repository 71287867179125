import React, {useEffect, useState, useCallback, useRef} from "react";

// CSS
import '../index.scss'
import {Form, Row} from "react-bootstrap";

// Common
import {handleDateFormat} from "../common/moment";

// Network
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_DELETE, AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../../common/Network/CommonFunction";
import {Col} from "reactstrap";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 생성 요청
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;
    case COMMAND.DATA_DELETE : {
      const {requestUrl} = params;
      response = await AXIOS_DELETE(requestUrl);
    }
      break;
    default:
      return null;
  }

  return response.data;
}

export const UserDetail = ({...props}) => {
  const {history} = props;
  const [detail, setDetail] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [groupList, setGroupList] = useState([]);

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        let tempState = JSON.parse(JSON.stringify(data.payload));
        tempState['password'] = '';
        setDetail(tempState);
      }
      if (command === COMMAND.DATA_CREATE || command === COMMAND.DATA_UPDATE) {
        // alert('저장되었습니다.');
        window.location.reload();
      }
      if(command === COMMAND.DATA_LIST) {
        if(action['params']['requestUrl'].indexOf('/branches/active') >= 0){
          setBranchList(data.payload.branch_list);
        }
        if(action['params']['requestUrl'].indexOf('/setting/group') >= 0){
          setGroupList(data.payload);
        }
      }
      if(command === COMMAND.DATA_DELETE){
        // alert('삭제되었습니다.');
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (props.userId !== 'new') {
      const command = COMMAND.DATA_INFO;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/' + props.userId
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      setDetail(
        {
          "employee_id": "",
          "name": "",
          "password": "",
          "group_id": 0,
          "branch_id": "",
          "active_yn": 1
        });
    }
  }, [props.userId]);

  useEffect(() => {
    // 지점 list, 그룹 list
    const command = COMMAND.DATA_LIST;

    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/branches/active'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);

    const command_group = COMMAND.DATA_LIST;

    const params_group = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/group'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command_group, params_group);
  }, []);

  const handleSave = () => {
    const command = COMMAND.DATA_CREATE;
    let postParams = {
      "employee_id": detail['employee_id'],
      "name": detail['name'],
      "password": detail['password'],
      "group_id": Number(detail['group_id']),
      "active_yn": Number(detail['active_yn'])
    };

    if(detail['branch_id']){
      Object.assign(postParams, {"branch_id": detail['branch_id'],})
    }

    if(detail['group_id'] === 0){
      alert('그룹을 지정해주세요.');
      return;
    }

    const params = {
      sendString: postParams,
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/signup'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleUpdate = () => {
    const command = COMMAND.DATA_UPDATE;

    let postParams = {
      "name": detail['name'],
      "password": detail['password'],
      "group_id": Number(detail['group_id']),
      "branch_id": detail['branch_id'],
      "active_yn": Number(detail['active_yn'])
    };

    const params = {
      sendString: postParams,
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/' + props.userId
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleDelete = () => {
    if(window.confirm(props.userId+'를 삭제하시겠습니까?')){

    } else return;
    const command = COMMAND.DATA_DELETE;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/' + props.userId
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleState = (state, value) => {
    let tempState = JSON.parse(JSON.stringify(detail));
    tempState[state] = value;
    setDetail(tempState);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"subTitleItem"}>상세 정보</div>
      <Form noValidate>

        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>유저 ID</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="유저 아이디"
              onChange={(e) => handleState('employee_id', e.target.value)}
              value={(detail && detail['employee_id']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>비밀번호</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="비밀번호"
              onChange={(e) => handleState('password', e.target.value)}
              value={(detail && detail['password']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>유저 이름</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="유저 이름"
              onChange={(e) => handleState('name', e.target.value)}
              value={(detail && detail['name']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>그룹</Form.Label>
            <div>
              <select className={"selectBox"} onChange={(e) => handleState('group_id', e.target.value)}>
                <option value={'ALL'} key={'ALL'}>전체</option>
                {(groupList || []).map((item, index) => {
                  return (
                    <option value={item['group_id']} key={index}
                            selected={detail && item['group_id'] === detail['group_id']}>{item['name']}</option>
                  )
                })}
              </select>
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>지점</Form.Label>
            <div>
              <select className={"selectBox"} onChange={(e) => handleState('branch_id', e.target.value)}>
                <option value={'ALL'} key={'ALL'}>전체</option>
                {(branchList || []).map((item, index) => {
                  return (
                    <option value={item['branch_id']} key={index}
                            selected={detail && item['branch_id'] === detail['branch_id']}>{item['branch_nm_ko']}</option>
                  )
                })}
              </select>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>활성화</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 1)}
                            checked={detail && detail['active_yn'] === 1}/>활성화</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 0)}
                            checked={detail && detail['active_yn'] === 0}/>비활성화</label>
            </div>
          </Form.Group>
        </Row>
        {props.userId !== 'new' ?
          <>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>최초 생성 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 생성 아이디"
                  value={(detail && detail['crt_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>최초 생성 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['crt_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>마지막 수정 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 아이디"
                  value={(detail && detail['upd_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>마지막 수정 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['upd_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
          </>
          : null}
      </Form>

      <div className={"buttons"}>
        {props.userId === 'new' ? <div className={'save_btn'} onClick={() => handleSave()}>저장</div> :
          <>
            <div className={'cancel_btn'} onClick={() => handleDelete()}>삭제</div>
            <div className={'save_btn'} onClick={() => handleUpdate()}>수정</div>
          </>}
      </div>
    </>
  );
};
