import {combineReducers} from 'redux';
import {loginReducer} from "../actions/loginReducer";
import { persistReducer } from 'redux-persist';	// 추가
import storage from 'redux-persist/lib/storage';	// 추가

const persistConfig = {
  key: 'root',
  storage,
}	// 추가

const reducers = combineReducers({loginFetch: loginReducer});

const persistedReducer = persistReducer(persistConfig, reducers);	// 추가

export default persistedReducer;	// 수정
