import React from "react";
import Pagination from 'react-js-pagination';

// CSS
import './css/Pagination.scss'

export const PaginationComponent = ({...props}) => {
  const handlePageChange = (data) => {
    props.setPage(data);
  };

  return (
    <Pagination
      activePage={props.page}
      itemsCountPerPage={10}
      totalItemsCount={props.totalCount}
      pageRangeDisplayed={10}
      prevPageText={"‹"}
      nextPageText={"›"}
      onChange={handlePageChange}
    />
  );
};
