import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';
import '../components/css/Table.scss'
import {Button, Table} from "react-bootstrap";

// Components
import {PaginationComponent} from "../components/Pagination";
import {RobotDetail} from "../components/Detail/RobotDetail";

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Robot: React.FC = (props: any) => {
  const [tab, setTab] = useState<number>(1);
  const [sort, setSort] = useState('robot_id');
  const [openModal, setOpenModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [robotList, setRobotList] = useState([]);
  const [clickedRobot, setClickedRobot] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        let tempList = JSON.parse(JSON.stringify(data.payload.list));
        for (let item of tempList) {
          item['is_checked'] = false;
        }
        setRobotList(tempList);
        setTotalCount(data.payload.meta.itemCount);
      }
      if (command === COMMAND.DATA_UPDATE) {
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if ((tab >= 0 && tab <= 2) || page || sort) {
      const command = COMMAND.DATA_LIST;
      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/robots?robot_class=' + tab + '&take=10&page=' + page + '&orderField=' + sort + '&order=ASC'
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    }
  }, [tab, page, sort])

  // const handleAdd = () => {
  //   setOpenModal(true);
  // }

  const handleUpdate = (data: string) => {
    setClickedRobot(data);
    setOpenModal(false);
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'robotContainer'}>
        <div className={'robotTab'}>
          <div className={tab === 1 ? 'tab on' : 'tab'} onClick={() => setTab(1)}>바리스</div>
          <div className={tab === 2 ? 'tab on' : 'tab'} onClick={() => setTab(2)}>아리스</div>
        </div>
        <div className={'robotContent'}>
          <div className={'sortBox'}>
            <div className={sort === 'robot_id' ? 'sortItem on' : 'sortItem'} onClick={() => setSort('robot_id')}>아이디순
            </div>
            <div className={'sortOption'}>|</div>
            <div className={sort === 'name' ? 'sortItem on' : 'sortItem'} onClick={() => setSort('name')}>이름순</div>
            <div className={'sortOption'}>|</div>
            <div className={sort === 'crt_dttm' ? 'sortItem on' : 'sortItem'}
                 onClick={() => setSort('crt_dttm')}>생성일자순
            </div>
          </div>
          <Table striped
            className={"robot-table-title"}
            style={{
              textAlign: "center",
              fontFamily: "Spoqa Han Sans Neo",
            }}
          >
            <thead>
            <tr>
              {windowWidth < 768 ?
                <>
                  <th>로봇 아이디</th>
                  <th>로봇 이름</th>
                  <th>설치현황</th>
                </> :
                <>
                  <th>로봇 아이디</th>
                  <th>로봇 이름</th>
                  <th>타입</th>
                  <th>설치현황</th>
                  <th>시리얼넘버</th>
                  <th>제조사</th>
                  <th>생일</th>
                  <th>원격관리</th>
                </>}

            </tr>
            </thead>
            <tbody>
            {(robotList || []).map((robot, i) => {
              return (
                <tr key={"robot_" + i} onClick={() => handleUpdate(robot['robot_id'])}>
                  {windowWidth < 768 ?
                    <>
                      <td>{robot['robot_id']}</td>
                      <td>{robot['name']}</td>
                      <td>{robot['branch_nm']}</td>
                    </> : <>
                      <td>{robot['robot_id']}</td>
                      <td>{robot['name']}</td>
                      <td>{robot['model_no']}</td>
                      <td>{robot['branch_nm']}</td>
                      <td>{robot['serial_no']}</td>
                      <td>{robot['manufacture']}</td>
                      <td>{robot['purchase_date']}</td>
                      <td>
                        <Button
                          style={{
                            backgroundColor: "#fff",
                            color: "#000",
                            borderColor: "darkgray",
                            width: '70px',
                            padding: '2px 0',
                            fontWeight: 500
                          }}
                        >
                          바로가기
                        </Button>
                      </td>
                    </>}
                </tr>
              );
            })}
            </tbody>
          </Table>
        </div>
        <div className={"buttonContainer"}>
          <div className={"saveBtn"} onClick={() => setClickedRobot('new')}>추가</div>
        </div>
        <PaginationComponent totalCount={totalCount} page={page} setPage={setPage}/>
        {clickedRobot ? <RobotDetail robotId={clickedRobot} /> : null}
      </div>
    </>
  );
};
