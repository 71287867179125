import React, {useEffect, useState} from "react";

// CSS
import './css/CardItem.scss'

// Common
import './common/common';
import {handleNumber} from "./common/common";

export interface item {
  item: itemProps;
}

export interface itemProps {
  idx: number;
  type: string;
  title: string;
  value: number[];
}

export const CardItem: React.FC<item> = ({...props}) => {
  const {item} = props;
  const [menu, setMenu] = useState('');

  useEffect(() => {
    if (window.location.pathname === '/') {
      setMenu('home');
    }
  }, []);

  return (
    <>
      <div className={'itemBox'} style={{'boxShadow': 'none', 'backgroundColor':'#f8f8f8'}}>
        <div className={'itemLeft'}>
          <img src="/static/img/dashboard/logo_baris.png" alt=""/>
        </div>
        <div className={'itemRight'}>
          <div className={'itemTitle'}>바리스가 일한 시간</div>
          <div className={'itemContent'}>{<div><span>{props.item && props.item[0] ? handleNumber(item[0].value[0]) : 0}</span>h <span>{props.item && props.item[0] ? item[0].value[1] : 0}</span>m</div>}</div>
        </div>
        <div className={'borderCss'}></div>
        <div className={'itemRight'}>
          <div className={'itemTitle'}>바리스가 만든 커피</div>
          <div className={'itemContent'}>{<div><span>{props.item && props.item[0] ? handleNumber(item[1].value[0]) : 0}</span>잔</div>}</div>
        </div>
      </div>
      <div className={'itemBox'} style={{'boxShadow': 'none', 'backgroundColor':'#f8f8f8'}}>
        <div className={'itemLeft'}>
          <img src="/static/img/dashboard/logo_aris.png" alt=""/>
        </div>
        <div className={'itemRight'}>
          <div className={'itemTitle'}>아리스가 일한 시간</div>
          <div className={'itemContent'}>{<div><span>{props.item && props.item[0] ? handleNumber(item[2].value[0]) : 0}</span>h <span>{props.item && props.item[0] ? item[2].value[1] : 0}</span>m</div>}</div>
        </div>
        <div className={'borderCss'}></div>
        <div className={'itemRight'}>
          <div className={'itemTitle'}>아리스가 만든 아이스크림</div>
          <div className={'itemContent'}>{<div><span>{props.item && props.item[0] ? handleNumber(item[3].value[0]) : 0}</span>개</div>}</div>
        </div>
      </div>
    </>
  );
};
