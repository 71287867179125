import React, {useEffect, useState, useCallback, useRef} from "react";

// CSS
import '../index.scss'
import {Table, Form, Row} from "react-bootstrap";

// Common
import {handleDateFormat} from "../common/moment";

// Network
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../../common/Network/CommonFunction";
import {Col} from "reactstrap";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 생성 요청
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;

      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;

      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const RobotDetail = ({...props}) => {
  const {history} = props;
  const [detail, setDetail] = useState({});
  const [branchList, setBranchList] = useState([]);
  const [recodeOpen, setRecordOpen] = useState(false);

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        setDetail(data.payload);
      }
      if (command === COMMAND.DATA_LIST) {
        setBranchList(data.payload.list);
      }
      if (command === COMMAND.DATA_CREATE) {
        alert('저장되었습니다.');
        window.location.reload();
      }
      if (command === COMMAND.DATA_UPDATE) {
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (props.robotId !== 'new') {
      const command = COMMAND.DATA_INFO;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/robots/' + props.robotId
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      setDetail(
        {
          'robot': {
            "robot_id": "",
            "name": "",
            "robot_code": "",
            "branch_id": "",
            "model_no": '',
            "serial_no": "",
            "manufacture": "",
            "purchase_date": "",
            "description": "",
          },
          'state': {
            'robot_class': '',
            'robot_code': '',
            'branch_id': '',
          }
        });
    }
  }, [props.robotId]);

  useEffect(() => {
    const command = COMMAND.DATA_LIST;

    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/branches?take=50'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, [])


  const handleSave = (data) => {
    if (props.robotId === 'new') { // 저장
      const command = COMMAND.DATA_CREATE;

      const tempParams = {
        'name': detail['robot']['name'],
        'robot_class': detail['state']['robot_class'],
        'robot_code': detail['state']['robot_code'],
        'branch_id': detail['state']['branch_id'],
        'model_no': detail['robot']['model_no'],
        'serial_no': detail['robot']['serial_no'],
        'manufacture': detail['robot']['manufacture'],
        'purchase_date': detail['robot']['purchase_date'],
        'description': detail['robot']['description'],
      };
      const params = {
        sendString: tempParams,
        requestUrl: process.env.REACT_APP_API_HOST + '/robots'
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      // 수정 API 호출
      const command = COMMAND.DATA_UPDATE;

      const tempParams = {
        'name': detail['robot']['name'],
        'robot_class': detail['state']['robot_class'],
        'robot_code': detail['state']['robot_code'],
        'branch_id': detail['state']['branch_id'],
        'model_no': detail['robot']['model_no'],
        'serial_no': detail['robot']['serial_no'],
        'manufacture': detail['robot']['manufacture'],
        'purchase_date': detail['robot']['purchase_date'],
        'description': detail['robot']['description'],
      }

      const params = {
        sendString: tempParams,
        requestUrl: process.env.REACT_APP_API_HOST + '/robots/' + props.robotId
      };

      /** back-end 데이터 처리 요청. */
      netWorkAgent.current.request(command, params);
      let stateDetail = {
        "robot_id": props.robotId,
        "branch_id": detail['state']['branch_id'],
        "robot_class": detail['state']['robot_class'],
        "robot_code": detail['state']['robot_code'],
        "site": detail['state']['site'],
        "pendant": Number(detail['state']['pendant']),
        "public_ip": detail['state']['public_ip'],
        "private_ip": detail['state']['private_ip'],
        "port": Number(detail['state']['port']),
        "status": Number(detail['state']['status'])
      }
      const stateParams = {
        sendString: stateDetail,
        requestUrl: process.env.REACT_APP_API_HOST + '/robots/state/' + props.robotId
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, stateParams);
    }
  }

  const handleState = (type, state, value) => {
    let tempState = JSON.parse(JSON.stringify(detail));
    tempState[type][state] = value;
    setDetail(tempState);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"subTitleItem"}>상세 정보</div>
      <Form noValidate>
        {props.robotId !== 'new' ?
          <Row className="mb-3">
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label>로봇 아이디</Form.Label>
              <Form.Control
                required
                readOnly={true}
                type="text"
                placeholder="ROBOT ID"
                value={(detail['robot'] && detail['robot']['robot_id']) || ''}
              />
            </Form.Group>
          </Row> : null}
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>로봇 이름</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="ROBOT NAME"
              onChange={(e) => handleState('robot', 'name', e.target.value)}
              value={(detail['robot'] && detail['robot']['name']) || ''}
            />
            {/*<Form.Control.Feedback>Looks good!</Form.Control.Feedback>*/}
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>모델 NO</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="모델 NO"
              onChange={(e) => handleState('robot', 'model_no', e.target.value)}
              value={(detail['robot'] && detail['robot']['model_no']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>시리얼 NO</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="시리얼 NO"
              onChange={(e) => handleState('robot', 'serial_no', e.target.value)}
              value={detail['robot'] && detail['robot']['serial_no']}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>제조사</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="제조사"
              onChange={(e) => handleState('robot', 'manufacture', e.target.value)}
              value={(detail['robot'] && detail['robot']['manufacture']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>구매일자</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="구매일자"
              onChange={(e) => handleState('robot', 'purchase_date', e.target.value)}
              value={(detail['robot'] && detail['robot']['purchase_date']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>설명</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="설명"
              onChange={(e) => handleState('robot', 'description', e.target.value)}
              value={(detail['robot'] && detail['robot']['description']) || ''}
            />
          </Form.Group>
        </Row>
        {/*{props.robotId !== 'new' ?*/}
        {/*  <>*/}
        {/*    <Row className="mb-3">*/}
        {/*      <Form.Group as={Col} md="6" controlId="validationCustom01">*/}
        {/*        <Form.Label>최초 생성 아이디</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          required*/}
        {/*          type="text"*/}
        {/*          readOnly={true}*/}
        {/*          placeholder="최초 생성 아이디"*/}
        {/*          defaultValue={detail['robot'] && detail['robot']['crt_id']}*/}
        {/*          value={detail['robot'] && detail['robot']['crt_id']}*/}
        {/*        />*/}
        {/*      </Form.Group>*/}
        {/*      <Form.Group as={Col} md="6" controlId="validationCustom02">*/}
        {/*        <Form.Label>최초 생성 날짜</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          required*/}
        {/*          type="text"*/}
        {/*          readOnly={true}*/}
        {/*          placeholder="최초 생성 날짜"*/}
        {/*          onChange={(e) => handleState('robot', 'crt_dttm', e.target.value)}*/}
        {/*          value={detail['robot'] && handleDateFormat('fullDate', detail['robot']['crt_dttm'])}*/}
        {/*        />*/}
        {/*      </Form.Group>*/}
        {/*    </Row>*/}
        {/*    <Row className="mb-3">*/}
        {/*      <Form.Group as={Col} md="6" controlId="validationCustom01">*/}
        {/*        <Form.Label>마지막 수정 아이디</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          required*/}
        {/*          type="text"*/}
        {/*          readOnly={true}*/}
        {/*          placeholder="마지막 수정 아이디"*/}
        {/*          defaultValue={detail['robot'] && detail['robot']['upd_id']}*/}
        {/*          value={detail['robot'] && detail['robot']['upd_id']}*/}
        {/*        />*/}
        {/*      </Form.Group>*/}
        {/*      <Form.Group as={Col} md="6" controlId="validationCustom02">*/}
        {/*        <Form.Label>마지막 수정 날짜</Form.Label>*/}
        {/*        <Form.Control*/}
        {/*          required*/}
        {/*          type="text"*/}
        {/*          readOnly={true}*/}
        {/*          placeholder="마지막 수정 날짜"*/}
        {/*          onChange={(e) => handleState('robot', 'upd_dttm', e.target.value)}*/}
        {/*          value={detail['robot'] && handleDateFormat('fullDate', detail['robot']['upd_dttm'])}*/}
        {/*        />*/}
        {/*      </Form.Group>*/}
        {/*    </Row>*/}
        {/*  </>*/}
        {/*  : null}*/}
      </Form>


      <div className={"subTitleItem"}>현재 상태</div>
      <Form noValidate>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="formGridState">
            <Form.Label>설치 현황</Form.Label>
            <div>
              <select className={"selectBox"} onChange={(e) => handleState('state', 'branch_id', e.target.value)}>
                <option value={'ALL'} key={'ALL'}>전체</option>
                {(branchList || []).map((item, index) => {
                  return (
                    <option value={item['branch_id']} key={index}
                            selected={detail['state'] && item['branch_id'] === detail['state']['branch_id']}>{item['branch_nm_ko']}</option>
                  )
                })}
              </select>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>로봇 구분</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('state', 'robot_class', "1")}
                            checked={detail['state'] && detail['state']['robot_class'] === "1"}/>바리스</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('state', 'robot_class', "2")}
                            checked={detail['state'] && detail['state']['robot_class'] === "2"}/>아리스</label>
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>로봇 코드</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('state', 'robot_code', '1')}
                            checked={detail['state'] && detail['state']['robot_code'] === '1'}/>드립</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('state', 'robot_code', '2')}
                            checked={detail['state'] && detail['state']['robot_code'] === '2'}/>에쏘</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('state', 'robot_code', '3')}
                            checked={detail['state'] && detail['state']['robot_code'] === '3'}/>브루</label>
              {/*<label><input className={'radio_btn'} type="radio"*/}
              {/*              onChange={() => handleState('state', 'robot_code', '4')}*/}
              {/*              checked={detail['state'] && detail['state']['robot_code'] === '4'}/>토핑</label>*/}
              {/*<label><input className={'radio_btn'} type="radio"*/}
              {/*              onChange={() => handleState('state', 'robot_code', '5')}*/}
              {/*              checked={detail['state'] && detail['state']['robot_code'] === '5'}/>논토핑</label>*/}
            </div>
          </Form.Group>
          {props.robotId !== 'new' ?
            <>
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>팬던트</Form.Label>
                <div className={"radio_box"}>

                  <label><input className={'radio_btn'} type="radio"
                                onChange={() => handleState('state', 'pendant', 1)}
                                checked={detail['state'] && detail['state']['pendant'] === 1}/>Panel Pendant</label>
                  <label><input className={'radio_btn'} type="radio"
                                onChange={() => handleState('state', 'pendant', 2)}
                                checked={detail['state'] && detail['state']['pendant'] === 2}/>Smart Pendant</label>
                </div>
                {/*<Form.Control*/}
                {/*  required*/}
                {/*  type="text"*/}
                {/*  placeholder="팬던트"*/}
                {/*  onChange={(e) => handleState('state', 'pendant', e.target.value)}*/}
                {/*  value={detail['state'] && detail['state']['pendant']}*/}
                {/*/>*/}
              </Form.Group>
            </> : null}
        </Row>
        {props.robotId !== 'new' ?
          <>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>외부 IP</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="외부 IP"
                  onChange={(e) => handleState('state', 'public_ip', e.target.value)}
                  value={detail['state'] && detail['state']['public_ip']}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>내부 IP</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="내부 IP"
                  onChange={(e) => handleState('state', 'private_ip', e.target.value)}
                  value={detail['state'] && detail['state']['private_ip']}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>포트</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="포트"
                  onChange={(e) => handleState('state', 'port', e.target.value)}
                  value={detail['state'] && detail['state']['port']}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>상태</Form.Label>
                <div className={"radio_box"}>
                  <label><input className={'radio_btn'} type="radio"
                                onChange={() => handleState('state', 'status', 1)}
                                checked={detail['state'] && detail['state']['status'] === 1}/>활성화</label>
                  <label><input className={'radio_btn'} type="radio"
                                onChange={() => handleState('state', 'status', 0)}
                                checked={detail['state'] && detail['state']['status'] === 0}/>비활성화</label>
                </div>
              </Form.Group>
            </Row>
            {/*<Row className="mb-3">*/}
            {/*  <Form.Group as={Col} md="6" controlId="validationCustom01">*/}
            {/*    <Form.Label>최초 생성 아이디</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*      required*/}
            {/*      type="text"*/}
            {/*      readOnly={true}*/}
            {/*      placeholder="최초 생성 아이디"*/}
            {/*      // defaultValue={detail['state'] && detail['state']['crt_id']}*/}
            {/*      value={detail['state'] && detail['state']['crt_id']}*/}
            {/*    />*/}
            {/*  </Form.Group>*/}
            {/*  <Form.Group as={Col} md="6" controlId="validationCustom02">*/}
            {/*    <Form.Label>최초 생성 날짜</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*      required*/}
            {/*      type="text"*/}
            {/*      readOnly={true}*/}
            {/*      placeholder="최초 생성 날짜"*/}
            {/*      // onChange={(e) => handleState('state', 'crt_dttm', e.target.value)}*/}
            {/*      value={detail['state'] && handleDateFormat('fullDate', detail['state']['crt_dttm'])}*/}
            {/*    />*/}
            {/*  </Form.Group>*/}
            {/*</Row>*/}
            {/*<Row className="mb-3">*/}
            {/*  <Form.Group as={Col} md="6" controlId="validationCustom01">*/}
            {/*    <Form.Label>마지막 수정 아이디</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*      required*/}
            {/*      type="text"*/}
            {/*      readOnly={true}*/}
            {/*      placeholder="마지막 수정 아이디"*/}
            {/*      // defaultValue={detail['state'] && detail['state']['upd_id']}*/}
            {/*      value={detail['state'] && detail['state']['upd_id']}*/}
            {/*    />*/}
            {/*  </Form.Group>*/}
            {/*  <Form.Group as={Col} md="6" controlId="validationCustom02">*/}
            {/*    <Form.Label>마지막 수정 날짜</Form.Label>*/}
            {/*    <Form.Control*/}
            {/*      required*/}
            {/*      type="text"*/}
            {/*      readOnly={true}*/}
            {/*      placeholder="마지막 수정 날짜"*/}
            {/*      // onChange={(e) => handleState('state', 'upd_dttm', e.target.value)}*/}
            {/*      value={detail['state'] && handleDateFormat('fullDate', detail['state']['upd_dttm'])}*/}
            {/*    />*/}
            {/*  </Form.Group>*/}
            {/*</Row>*/}
          </>
          : null}
      </Form>

      {detail['record'] ?
        <>
          <div className={"subTitleItem"} style={{'cursor': 'pointer'}} onClick={() => setRecordOpen(!recodeOpen)}>이력
            확인하기 <img
              src={recodeOpen ? '/static/img/dashboard/ic_down_arrow.png' : '/static/img/dashboard/ic_up_arrow.png'}
              alt={''}/></div>
          {recodeOpen ?
            <Table
              className={"robot-table-title"}
              style={{
                textAlign: "center",
                fontFamily: "Spoqa Han Sans Neo",
                minWidth: "max-content",
              }}
            >
              <thead>
              <tr>
                <th>로봇 아이디</th>
                <th>SEQ</th>
                <th>로봇등기대분류</th>
                <th>로봇등기코드</th>
                <th>로봇등기값</th>
                <th>최초생성아이디</th>
                <th>최초생성일자</th>
                <th>마지막수정아이디</th>
                <th>마지막수정일자</th>
              </tr>
              </thead>
              <tbody>
              {(detail && detail['record']).map((record, i) => {
                return (
                  <tr key={i}>
                    <td>{record['robot_id'] || '-'}</td>
                    <td>{record['seq'] || '-'}</td>
                    <td>{record['record_class'] || '-'}</td>
                    <td>{record['record_code'] || '-'}</td>
                    <td>{record['record_value'] || '-'}</td>
                    <td>{record['crt_id'] || '-'}</td>
                    <td>{handleDateFormat('date', record['crt_dttm']) || '-'}</td>
                    <td>{record['upd_id']}</td>
                    <td>{handleDateFormat('date', record['upd_dttm']) || '-'}</td>
                  </tr>
                );
              })}
              </tbody>
            </Table>
            : null} </> : null}
      <div className={"buttons"}>
        {/*<div className={"cancel_btn"}>취소</div>*/}
        <div className={'save_btn'} onClick={(e) => handleSave(e)}>{props.robotId === 'new' ? '저장' : '수정'}</div>
      </div>
    </>
  );
};
