import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';

// Components
import {CardItem} from '../components/CardItem'
import {LineChart} from '../components/LineChart'
import {BarChart} from '../components/BarChart'
import {PieChart} from '../components/PieChart'
import {Map} from "../components/Map";

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET} from "../common/Network/CommonFunction";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    case 'ROBOT_RANK' : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    case 'SALES_MONTH' : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    case 'BRANCH_LIST' : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Home: React.FC = (props: any) => {
  const [chartType, setChartType] = useState<string>('line');
  const [mapPosition, setMapPosition] = useState<string>('all');
  const [cardItem, setCardItem] = useState<any>([]);
  const [pieItem, setPieItem] = useState<any>([]);
  const [pieTemp, setPieTemp] = useState<any>([]);
  const [saleMonth, setSaleMonth] = useState<any>([]);
  const [saleBarMonth, setSaleBarMonth] = useState<any>([]);
  const [month, setMonth] = useState<string>('all');
  const [mapMarker, setMapMarker] = useState<any>([]);
  const [robotCount, setRobotCount] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(0);

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;
      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setCardItem([
          {
            idx: 0,
            type: 'time',
            title: '바리스가 일한 시간',
            value: [Math.floor(data.payload.work[0].total_time / 60), data.payload.work[0].total_time % 60]
          },
          {
            idx: 1,
            type: 'cup',
            title: '바리스가 만든 커피',
            value: [data.payload.work[0].total_qty, 0]
          },
          {
            idx: 2,
            type: 'time',
            title: '아리스가 일한 시간',
            value: [Math.floor(data.payload.work[1].total_time / 60), data.payload.work[1].total_time % 60]
          },
          {
            idx: 3,
            type: 'cup',
            title: '아리스가 만든 아이스크림',
            value: [data.payload.work[1].total_qty, 0]
          }])

        setRobotCount(data.payload.count);
      }
      if (command === 'ROBOT_RANK') {
        setPieItem(data.payload);
        setPieTemp(data.payload);
      }
      if (command === 'SALES_MONTH') {
        for (let item of data.payload.line) {
          if(item['id'].indexOf('ARIS') >= 0) item['unit'] = '개';
          if(item['id'].indexOf('BARIS') >= 0) item['unit'] = '잔';

          for (let data of item['data']) {
            if (data['x'].length > 6 && data['x'].substr(6, 8) !== '01') {
              data['x'] = data['x'].substr(4, 8);
            }
            // data['y'] += 's';
          }
        }
        for (let item of data.payload.bar) {
          if (item['date'].length > 6 && item['date'].substr(6, 8) !== '01') {
            item['date'] = item['date'].substr(4, 8);
          }
        }

        setSaleMonth(data.payload.line);
        setSaleBarMonth(data.payload.bar);
      }
      if (command === 'BRANCH_LIST') {
        setMapMarker(data.payload.list);
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/home/work'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    const command = 'ROBOT_RANK';
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/home/rank'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (month) {
      const command = 'SALES_MONTH';
      const params = {
        requestUrl: month === 'all' ? process.env.REACT_APP_API_HOST + '/home/sales/robot/month/chart' : process.env.REACT_APP_API_HOST + '/home/sales/robot/day/2022' + month
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    }
  }, [month]);

  /** back end 통신 example  */
  useEffect(() => {
    const command = 'BRANCH_LIST';
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/branches?take=30'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  // pieChart Data 조작
  useEffect(() => {
    if (pieTemp && 'robot' in pieTemp) {
      let tempState = JSON.parse(JSON.stringify(pieTemp));
      let robotSum = 0;
      let barisSum = 0
      let arisSum = 0;
      for (let [index, item] of tempState['robot'].entries()) {
        item.id = item.name;
        item.label = item.robot_id;
        item.value = item.percent;

        if (index >= 4) robotSum += Math.floor(Number(item.percent));
      }
      for (let [index, item] of tempState['baris'].entries()) {
        item.id = item.name;
        item.label = item.robot_id;
        item.value = item.percent;

        if (index >= 4) barisSum += Math.floor(Number(item.percent));
      }
      for (let [index, item] of tempState['aris'].entries()) {
        item.id = item.name;
        item.label = item.robot_id;
        item.value = item.percent;

        if (index >= 4) arisSum += Math.floor(Number(item.percent));
      }

      tempState['robot'] = tempState['robot'].slice(0, 4);
      if (robotSum > 0) {
        tempState['robot'].push({
          id: '기타',
          label: '기타',
          value: robotSum,
        });
      }

      tempState['baris'] = tempState['baris'].slice(0, 4);
      if (barisSum > 0) {
        tempState['baris'].push({
          id: '기타',
          label: '기타',
          value: barisSum,
        });
      }
      tempState['aris'] = tempState['aris'].slice(0, 4);
      if (arisSum > 0) {
        tempState['aris'].push({
          id: '기타',
          label: '기타',
          value: arisSum,
        });
      }
      setPieItem(tempState);
    }
  }, [pieTemp]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    // window.addEventListener('resize', handleResize)
  }, []);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'mainContainer'}>
        <div className={'overview'}>
          <div className={'overviewTitle'}>기본 데이터</div>
          <CardItem item={cardItem}/>
        </div>
        <div className={'overview'}>
          <div className={'overviewTab'}>
            <div className={'overviewTitle'}>기간별 로봇 제조량</div>
            <div className={'chartIconContainer'}>
              <div className={'chart_icon'} onClick={() => setChartType('line')}>
                {chartType === 'line' ? <img src="/static/img/dashboard/linegraph_on.png" alt=""/> :
                  <img src="/static/img/dashboard/linegraph_off.png" alt=""/>}
              </div>
              <div className={'chart_icon'} onClick={() => setChartType('bar')}>
                {chartType === 'bar' ? <img src="/static/img/dashboard/bargraph_on.png" alt=""/> :
                  <img src="/static/img/dashboard/bargraph_off.png" alt=""/>}
              </div>
            </div>
            <select className={"selectBox"} onChange={(e) => setMonth(e.target.value)}>
              <option value={'all'} key={'all'}>전체</option>
              {
                [...Array(12)].map((x, i) =>
                  <option value={i + 1 < 10 ? '0' + (i + 1) : i + 1}
                          key={i}> {i + 1 < 10 ? '0' + (i + 1) : i + 1} 월</option>
                )}
            </select>
          </div>
          <div className={'overviewContent'}>
            {chartType === 'line' && saleMonth && saleMonth.length > 0 ?
              <LineChart data={saleMonth} width={windowWidth < 768 ? windowWidth : 1000} page={'HOME'} />:
              <BarChart data={saleBarMonth} width={windowWidth < 768 ? windowWidth : 1000} page={'HOME'}/>}
          </div>
        </div>

        <div className={'overview'} style={{'marginTop': '40px', 'flexDirection': 'column'}}>
          <div className={'overviewTitle'}>로봇 제조 순위</div>
          <div className={"layout_container"}>
            <div className={"chart_container"}>
              <div className={"layout_title"}>전체 로봇 제조 비율</div>
              <div className={"graph-wrap"}>
                {pieItem && 'robot' in pieItem ? <PieChart data={pieItem['robot']} width={windowWidth < 768 ? windowWidth : 1000}/> : null}
              </div>
            </div>
            <div className={"chart_container"}>
              <div className={"layout_title"}>바리스 제조 비율</div>
              <div className={"graph-wrap"}>
                {pieItem && 'robot' in pieItem ? <PieChart data={pieItem['baris']} width={windowWidth < 768 ? windowWidth : 1000}/> : null}
              </div>
            </div>
            <div className={"chart_container"}>
              <div className={"layout_title"}>아리스 제조 비율</div>
              <div className={"graph-wrap"}>
                {pieItem && 'robot' in pieItem ? <PieChart data={pieItem['aris']} width={windowWidth < 768 ? windowWidth : 1000}/> : null}
              </div>
            </div>
          </div>
        </div>

        <div className={'overview'} style={{'paddingBottom': '0'}}>
          <div className={'overviewTab'}>
            <div className={'overviewTitle'}>로봇 상태 맵</div>
          </div>
          <div className={"map-button-wrap"}>
            <div className={'buttonWrap'}>
              <button type="button" className="btn btn-outline-dark" onClick={() => setMapPosition('all')}>전체</button>
              <button type="button" className="btn btn-outline-dark" onClick={() => setMapPosition('seoul')}>서울</button>
              <button type="button" className="btn btn-outline-dark" onClick={() => setMapPosition('gyeonggi')}>경기도
              </button>
              <button type="button" className="btn btn-outline-dark" onClick={() => setMapPosition('jeju')}>제주도</button>
              <div className={'robotCount'}>전체 로봇 수: {robotCount || 0}</div>
            </div>
          </div>

          <div className={'overviewContent'} style={{'height': 'fit-content'}}>
            <div className={'mapLegend'}>
              <div className={'legendItem'}>
                <img src={process.env.PUBLIC_URL + "/static/img/maps/logo_baris.png"} alt={''}/>
                <span className={"legend-icon-title"}>바리스</span></div>
              <div className={'legendItem'}>
                <img src={process.env.PUBLIC_URL + "/static/img/maps/logo_aris.png"} alt={''}/>
                <span className={"legend-icon-title"}>아리스</span></div>
            </div>
            <Map width={windowWidth} markerProp={mapMarker} mapPosition={mapPosition}/>

          </div>
        </div>
      </div>
    </>
  );
};
