/**
 *  @summary
 *  공통으로 사용하는 상수 정의
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 영역
/*################################################################################*/
/**
 *  @constant
 *  @type {boolean}
 *  @description  debug console 출력 여부
 *  Todo : "빌드" 시 false 로 설정하고 빌드 , "개발" 시 true로 설정
 */
export const DEBUG_CONSOLE = false ;

/**
 *  @constant
 *  @type {Object}
 *  @description  HTTP 응답 에러 정보
 */
export const HTTP_STATUS_CODE = {
  SUCCESS: 200,
  SERVER_ERROR: 500,
};

/**
 *  @constant
 *  @type {Object}
 *  @description  에러 정보
 */
export const ERROR_CODE = {
  NO_ERROR: 0,
  AUTH_ERROR: 1,
  NO_TOKEN: 2,
  TOKEN_EXPIRED: 3,
  VERIFY_FAIL: 4,
  NO_USER: 5,
  LOCK_AUTH_FAIL: 6,
  NO_DATA: 7,
  UNAUTHORIZED: 401,
};

/**
 *  @constant
 *  @type {String}
 *  @description  로그인 URL
 */
export const LOGIN_URL = "/login";



