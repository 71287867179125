import React from "react";
import { ResponsivePie } from "@nivo/pie";

// CSS
import './css/CardItem.scss'

export const PieChart  = ({...props}) => {

  return (
    <ResponsivePie
      data={props.data}
      width={props.width < 768 ? 300 : 320}
      arcLabel={d => `${d.value}%`}
      margin={{ right: 60, bottom: 40, left: 60, top: 40 }}
      sortByValue={true}
      padAngle={0}
      colors={['#4664ff' , '#25bcde', '#1f2d80', '#ffcc46', '#ff9e45', '#41c38f', '#f3546a']}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            0.2
          ]
        ]
      }}
      enableArcLabels={true}
      enableArcLinkLabels={true}
      // enableArcLinkLabels={props.width >= 768}
      arcLinkLabelsDiagonalLength={6}
      arcLinkLabelsStraightLength={4}
      arcLinkLabelsTextOffset={5}
      arcLinkLabelsThickness={1}
      arcLinkLabelsTextColor={{"theme":"labels.text.fill"}}
      arcLabelsTextColor={['#ffffff']}
    />
  );
};
