import React, {useEffect, useState, useCallback, useRef} from "react";

// CSS
import './css/Map.scss'

// Components
import {LineChart} from './LineChart'

// Common
import {handleNumber} from "./common/common";

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Map = ({...props}) => {
  const [branchDetail, setBranchDetail] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [shopDetail, setShopDetail] = useState({'robot_id': 'RB000015'});
  const [robotOrder, setRobotOrder] = useState(0);

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;
      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        setBranchDetail(data.payload);
        setLineData(data.payload.line);
        // TODO: 코드 정리
        if(data.payload.line && data.payload.line.length > 0) {
          if(data.payload.line[robotOrder] == undefined) {
            return;
          }

          let tempState = [data.payload.line[robotOrder]];
          // console.log(tempState)
          // setLineData(tempState);
        }
      }
      if (command === COMMAND.DATA_UPDATE) {
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  }, [robotOrder]);


  /** back end 통신 example  */
  useEffect(() => {
    const command = COMMAND.DATA_INFO;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/home/robot/' + shopDetail.robot_id
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, [shopDetail]);

  useEffect(() => {
    if (props.mapPosition) {
      // console.log(props.mapPosition)
      if (props.mapPosition === 'all' || props.mapPosition === 'seoul') {
        setShopDetail({'robot_id': "RB000015"});
      } else if (props.mapPosition === 'gyeonggi') {
        setShopDetail({'robot_id': 'RB000003'});
      } else if (props.mapPosition === 'jeju') {
        setShopDetail({'robot_id': 'RB000008'});
      }
    }
  }, [props.mapPosition]);

  const position = {
    'all': [36.35051146978581, 127.38495725583753],
    'seoul': [37.52747355844564, 126.9815143810132],
    'gyeonggi': [37.36393449265334, 127.10537823055488],
    'jeju': [33.389823448383574, 126.36626219084842],
  };

  const [chartData, setChartData] = useState([]);

  let MARKER_WIDTH = 33, // 기본, 클릭 마커의 너비
    MARKER_HEIGHT = 36, // 기본, 클릭 마커의 높이
    OFFSET_X = 12, // 기본, 클릭 마커의 기준 X좌표
    OFFSET_Y = MARKER_HEIGHT, // 기본, 클릭 마커의 기준 Y좌표
    SPRITE_WIDTH = 25, // 스프라이트 이미지 너비
    SPRITE_HEIGHT = 25; // 스프라이트 이미지 높이

  useEffect(() => {
    if (props.markerProp && props.markerProp.length > 0) {
      let markerSize = new window.kakao.maps.Size(MARKER_WIDTH, MARKER_HEIGHT), // 기본, 클릭 마커의 크기
        markerOffset = new window.kakao.maps.Point(OFFSET_X, OFFSET_Y), // 기본, 클릭 마커의 기준좌표
        spriteImageSize = new window.kakao.maps.Size(SPRITE_WIDTH, SPRITE_HEIGHT); // 스프라이트 이미지의 크기

      let positions = [],
        positionsData = [],
        selectedMarker = null; // 클릭한 마커를 담을 변수

      for (let data of props.markerProp) {
        let a = 0, b = 0;
        let de = 0.0015;

        let aris_idx = 0, baris_idx = 0;
        for (const robot of data['robot']) {
          if(robot['robot_class'] === '1'){ // 바리스
            baris_idx += 0.001;
            positions.push(
              new window.kakao.maps.LatLng(data['coord_x'], data['coord_y'] + baris_idx),
            );
          } else if(robot['robot_class'] === '2'){ // 아리스
            aris_idx += 0.001;
            positions.push(
              new window.kakao.maps.LatLng(data['coord_x'] + de, data['coord_y'] + aris_idx),
            );
          }

          // b += 0.001;
          positionsData.push({...data,
            ...{
              robot_class: robot['robot_class'],
              robot_id: robot['robot_id']
            }});
          // positionsData.push({...data, index: i});
        }

        // for (let i = 0; i < data['aris_count']; i++) {
        //   positions.push(
        //     new window.kakao.maps.LatLng(data['coord_x'] + de, data['coord_y'] + a),
        //   );
        //   a += 0.001;
        //
        //   positionsData.push({...data,
        //   ...{
        //     aris_index: i,
        //     baris_index: 0,
        //     brand: 'ARIS'
        //   }});
        // }
        //
        // for (let i = 0; i < data['baris_count']; i++) {
        //   positions.push(
        //     new window.kakao.maps.LatLng(data['coord_x'], data['coord_y'] + b),
        //   );
        //   b += 0.001;
        //   positionsData.push({...data,
        //     ...{
        //       aris_index: 0,
        //       baris_index: i,
        //       brand: 'BARIS'
        //     }});
        //   // positionsData.push({...data, index: i});
        // }

        // positions.push(
        //   new window.kakao.maps.LatLng(data['coord_x'], data['coord_y']+a),
        // );


      }

      let mapContainer = document.getElementById('map'), // 지도를 표시할 div
        mapOption = {
          center: new window.kakao.maps.LatLng(position[props.mapPosition][0], position[props.mapPosition][1]), // 지도의 중심좌표
          level: props.mapPosition === 'all' ? 13 : 8 // 지도의 확대 레벨
        };

      let map = new window.kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
      let //
        normalOrigin = '/static/img/maps/logo_baris.png',
        clickOrigin = '/static/img/maps/ic_baris_on.png',
        normalOrigin_brown = '/static/img/maps/logo_aris.png',
        clickOrigin_brown = '/static/img/maps/ic_aris_on.png';

      // 지도 위에 마커를 표시합니다
      for (let i = 0; i < positions.length; i++) {
        // 마커를 생성하고 지도위에 표시합니다
        addMarker(positions[i], normalOrigin, clickOrigin, normalOrigin_brown, clickOrigin_brown, positionsData[i]);
      }

      // 마커를 생성하고 지도 위에 표시하고, 마커에 mouseover, mouseout, click 이벤트를 등록하는 함수입니다
      function addMarker(position, normalOrigin, clickOrigin, normalOrigin_brown, clickOrigin_brown, positionsData) {

        // 기본 마커이미지, 오버 마커이미지, 클릭 마커이미지를 생성합니다
        let normalImage = createMarkerImage(markerSize, markerOffset, normalOrigin),
          clickImage = createMarkerImage(markerSize, markerOffset, clickOrigin),
          normalImage_brown = createMarkerImage(markerSize, markerOffset, normalOrigin_brown),
          clickImage_brown = createMarkerImage(markerSize, markerOffset, clickOrigin_brown);

        // 마커를 생성하고 이미지는 기본 마커 이미지를 사용합니다
        let marker = new window.kakao.maps.Marker({
          map: map,
          position: position,
          image: positionsData.robot_class === '1' ? normalImage : normalImage_brown
        });

        // 마커 객체에 마커아이디와 마커의 기본 이미지를 추가합니다
        marker.normalImage = positionsData.robot_class === '1' ? normalImage : normalImage_brown;

        // 마커에 click 이벤트를 등록합니다
        window.kakao.maps.event.addListener(marker, 'click', function () {

          // 클릭된 마커가 없고, click 마커가 클릭된 마커가 아니면
          // 마커의 이미지를 클릭 이미지로 변경합니다
          if (!selectedMarker || selectedMarker !== marker) {

            // 클릭된 마커 객체가 null이 아니면
            // 클릭된 마커의 이미지를 기본 이미지로 변경하고
            !!selectedMarker && selectedMarker.setImage(selectedMarker.normalImage);

            // 현재 클릭된 마커의 이미지는 클릭 이미지로 변경합니다
            let tempClickImg = positionsData.robot_class === '1' ? clickImage : clickImage_brown;
            marker.setImage(tempClickImg);
          }

          // 클릭된 마커를 현재 클릭된 마커 객체로 설정합니다
          selectedMarker = marker;
          // console.log(positionsData['index'])
          let tempIndex = positionsData['aris_index'] === 0 ? positionsData['baris_index'] : positionsData['aris_index'];
          setRobotOrder(tempIndex);
          setShopDetail(positionsData);
        });
      }

      // MakrerImage 객체를 생성하여 반환하는 함수입니다
      function createMarkerImage(markerSize, offset, spriteOrigin) {
        let markerImage = new window.kakao.maps.MarkerImage(
          spriteOrigin, // 스프라이트 마커 이미지 URL
          markerSize, // 마커의 크기
          {
            offset: offset, // 마커 이미지에서의 기준 좌표
            spriteOrigin: spriteOrigin, // 스트라이프 이미지 중 사용할 영역의 좌상단 좌표
            spriteSize: spriteImageSize // 스프라이트 이미지의 크기
          }
        );

        return markerImage;
      }
    }
  }, [props.markerProp, props.mapPosition, chartData]);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className="map-wrap" style={{'display': 'flex'}}>
        <div id="map" style={{minWidth: props.width < 768 ? '100%' : '450px', height: props.width < 768 ? '200px' : '500px', backgroundColor: "#000"}}/>
        <div className={"map_detail_container"}>
          {/*<div className={"map_detail_title"}><span>선택 위치</span> : {branchDetail['branch_nm'] || '라운지엑스'}</div>*/}
          <div className={'robotName'}>{lineData && lineData.length > 0 && lineData[0]['id']}</div>
          <div className={'branchName'}>{branchDetail['branch_nm']}</div>

          <div className={"bottom_container"}>
            <div className={"info_item"}>
              <div className={"item_title"}>전체 제조량</div>
              <div className={'divide'}></div>
              <div className={"item_content"}>{handleNumber(Math.floor(Number(branchDetail['total_qty'] || '0'))) } 잔</div>
            </div>
            <div className={"info_item"}>
              <div className={"item_title"}>하루 평균 제조량</div>
              <div className={'divide'}></div>

              <div className={"item_content"}>{handleNumber(Math.floor(Number(branchDetail['avg_qty'] || '0')))} 잔</div>
            </div>
            <div className={"info_item"}>
              <div className={"item_title"}>하루 최대기록</div>
              <div className={'divide'}></div>
              <div className={"item_content"}>{handleNumber(Math.floor(Number(branchDetail['max_qty'] || '0')))} 잔</div>
            </div>
          </div>
          <div className={'mapGraph'}>
            <div className={'lineTitle'}>월 별 제조량</div>
            <LineChart data={lineData} width={props.width < 768 ? 500 : 700} height={250} type={'map'} page={'HOME'}/>
            {/*<LineChart data={lineData} width={props.width < 400 ? 400 : 500} height={250} type={'map'}/>*/}
          </div>
        </div>
      </div>
    </>
  );
};
