import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';

// Components
import {Button, Table} from "react-bootstrap";
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_DOWNLOAD, AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";

// Common
import '../components/common/common'
import {handleNumber, handleState} from "../components/common/common";
import {handleDateFormat} from "../components/common/moment";
import {PaginationComponent} from "../components/Pagination";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    case 'DOWNLOAD' : {
      const {requestUrl} = params;
      response = await AXIOS_DOWNLOAD(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    default:
      return null;
  }

  return response.data;
}

export const Point: React.FC = (props: any) => {
  const [cardNum, setCardNum] = useState('');
  const [detail, setDetail] = useState([]);
  const [paymentState, setPaymentState] = useState('0');
  const [status, setStatus] = useState('detail');
  const [loading, setLoading] = useState(false);
  const [allList, setAllList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [total, setTotal] = useState([]);
  const [page, setPage] = useState(1);
  const [post, setPost] = useState({
    staff: '',
    payment: '',
    charge: '',
    purchase_type: '1',
  });
  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback(async (action: any, data: any) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setAllList(data.payload.list);
        setTotalCount(data.payload.meta && data.payload.meta.itemCount);
        setLoading(false);
      }
      if (command === COMMAND.DATA_INFO) {
        if (data.payload.card === null) {
          alert('조건에 맞는 카드가 없습니다.');
          return;
        }
        setTotal(data.payload.total);
        setDetail(data.payload.card);
        setLoading(false);
      }
      if (command === COMMAND.DATA_CREATE) {
        setLoading(true);
      }
      if (command === 'DOWNLOAD'){
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `pointcard_${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      if(command === COMMAND.DATA_UPDATE){
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  }, []);

  useEffect(() => {
    if (page && paymentState && allList && allList.length > 0) {
      const command = COMMAND.DATA_LIST;
      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/pointcard?orderField=crt_dttm&order=DESC&page=' + page + (paymentState === '0' ? '' : '&payment_service=' + paymentState) + (status === 'detail' ? '&card_id=' + cardNum : '')
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    }
  }, [page, paymentState]);

  const handleSearch = () => {
    if(cardNum.length === 0){
      alert('카드 번호를 입력해주세요.');
      return;
    }
    setStatus('detail');
    setPage(1);
    setPaymentState('0');
    const command = COMMAND.DATA_INFO;

    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/detail/' + cardNum
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  useEffect(() => {
    if(detail && status === 'detail') {
      const listCommand = COMMAND.DATA_LIST;

      const listParams = {
        requestUrl: process.env.REACT_APP_API_HOST + '/pointcard?orderField=crt_dttm&order=DESC&page=' + page + (paymentState === '0' ? '' : '&payment_service=' + paymentState) + (cardNum ? '&card_id=' + cardNum : '')
      };
      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(listCommand, listParams);
    }
  }, [detail, loading]);

  const handlePayment = () => {
    if (post.staff === '') {
      alert('관리자 닉네임을 입력해주세요.');
      return;
    }
    if (post.payment === '') {
      alert('결제 금액을 입력해주세요.');
      return;
    }
    if (detail['money'] < post.payment) {
      alert('충전된 금액보다 많습니다.');
      return;
    }
    if(Number(post.payment) > 500000) {
      alert('최대 50만원까지 사용 가능합니다.');
      return;
    }
    if (window.confirm(post.staff + '가 ' + handleNumber(post.payment) + '원 사용하는게 맞습니까?')) {

    } else {
      return;
    }
    const command = COMMAND.DATA_CREATE;

    let tempState = JSON.parse(JSON.stringify(post));
    post['card_id'] = cardNum;
    post['purchase'] = post['payment'];
    setPost(tempState);

    const params = {
      sendString: post,
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/payment'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleCharge = () => {
    if (post.staff === '') {
      alert('관리자 닉네임을 입력해주세요.');
      return;
    }
    if (post.charge === '') {
      alert('충전 금액을 입력해주세요.');
      return;
    }
    if(Number(post.charge) > 500000) {
      alert('최대 50만원까지 충전 가능합니다.');
      return;
    }
    if (window.confirm(post.staff + '가 ' + handleNumber(post.charge) + '원 충전하는게 맞습니까?')) {

    } else {
      return;
    }
    const command = COMMAND.DATA_CREATE;

    let tempState = JSON.parse(JSON.stringify(post));
    post['card_id'] = cardNum;
    post['purchase'] = post['charge'];
    setPost(tempState);

    const params = {
      sendString: post,
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/charge'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleCancel = (id: number, card_id: number) => {
    if (post.staff === '') {
      alert('관리자 닉네임을 입력해주세요.');
      return;
    }
    if (window.confirm(post.staff + '가 취소하는게 맞습니까?')) {

    } else {
      return;
    }
    const command = COMMAND.DATA_CREATE;
    const params = {
      sendString: {
        id: id.toString(),
        card_id: card_id,
        staff: post.staff
      },
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/cancel'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const searchAll = () => {
    setStatus('list');
    setPage(1);
    setPaymentState('0');

    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard?orderField=crt_dttm&order=DESC'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);

    const detailCommand = COMMAND.DATA_INFO;
    const detailParams = {
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/total'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(detailCommand, detailParams);
  }

  const downloadExcel = () => {
    const command = 'DOWNLOAD';
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/pointcard/download' + (status === 'detail' ? '?card_id=' + cardNum : '')
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'pointContainer'}>
        <div className={'pointTab'}>포인트카드</div>
        <div className={'searchBox'}>
          <span style={{'alignSelf': 'center', 'marginRight': '20px'}}>카드 일련번호</span>
          <input className={'searchInput'} placeholder={'일련번호'} value={cardNum}
                 onChange={(e) => setCardNum(e.target.value)}/>
          <div className={'searchBtn'} onClick={() => handleSearch()}>검색</div>
          <div className={'allCard'} onClick={() => searchAll()}>전체 카드 사용내역 확인하기</div>
        </div>

        {status === 'list' ?
          <div className={'pointAllContainer'}>
            <div className={'contentTitle'}>
              <span style={{'marginRight': '20px'}}>사용내역</span>
              <div className={'cardInfo'}>
                <div>총 충전금액</div>
                <span>{total ? handleNumber(Number(total['total_charge'])) : 0}원</span>
                <div>총 사용금액</div>
                <span>{total ? handleNumber(Number(total['total_payment'])) : 0}원</span>
              </div>
              <div className={'excelContent'}>
                <img src="/static/img/dashboard/ic_excel.png" alt=""/>
                <div onClick={() => downloadExcel()}>내보내기</div>
              </div>
            </div>
            <Table className={"robot-table-title"} style={{textAlign: "center", fontFamily: "Spoqa Han Sans Neo",}}>
              <thead>
              <tr>
                <th>카드번호</th>
                <th>일시</th>
                <th>지점</th>
                <th>
                  <select style={{'cursor': 'pointer'}} onChange={(e) => setPaymentState(e.target.value)}>
                    <option value={'0'}>거래유형</option>
                    <option value={'1'}>충전</option>
                    <option value={'2'}>사용</option>
                  </select>
                </th>
                <th>결제수단</th>
                <th>변동금액</th>
                <th>잔액</th>
                <th>관리자</th>
              </tr>
              </thead>
              <tbody>
              {(allList || []).map((item, index) => {
                return (
                  <tr key={"robot_" + index}>
                    <td>{item['card_id']}</td>
                    <td>{handleDateFormat('fullDate', item['crt_dttm'])}</td>
                    <td>{item['branch_nm']}</td>
                    <td
                      style={item['payment_service'] === '충전' ? {'color': '#4664FF'} : {}}>{item['payment_service']}</td>
                    <td>{item['tool'] == null ? 'NONE' : item['tool']}</td>
                    <td>{handleNumber(item['purchase'])}원</td>
                    <td>{handleNumber(item['changes'])}원</td>
                    <td>{item['staff']}</td>
                  </tr>
                )
              })}

              </tbody>
            </Table>
            <PaginationComponent totalCount={totalCount} page={page} setPage={setPage}/>
          </div>
          : null
        }

        {status === 'detail' && detail && 'card_id' in detail ?
          <div className={'pointContent'}>
            <div className={'contentTitle'}>
              {detail['card_id'] || '-'} 카드
            </div>
            <div className={'contentDetail'}>
              <div className={'detailInfo'}>
                <div className={'infoTitle'}>잔액</div>
                <div className={'infoValue'}>{handleNumber(detail['money']) || 0}원</div>
                <div className={'infoTitle'}>사용기한</div>
                <div className={'infoValue'}>{handleDateFormat('date', detail['expiration_date'])}</div>
                <div className={'infoTitle'}>상태</div>
                <div className={'infoValue'}>{detail['enable_state'] === '1' ? '활성화' : '비활성화'}</div>
              </div>
              <div className={'pointUse'}>
                <input className={'pointInput'} placeholder={'관리자 닉네임'} value={post['staff']}
                       onChange={(e) => handleState(post, setPost, 'staff', e.target.value)}/>
              </div>
              <div className={'pointUse'}>
                <input className={'pointInput'} placeholder={'결제 금액'}
                       onChange={(e) => handleState(post, setPost, 'payment', Number(e.target.value))}/>
                <div className={'pointBtn'} onClick={() => handlePayment()}>사용하기</div>
              </div>
              <div className={'pointUse'} style={{'marginBottom': '50px'}}>
                <input className={'pointInput'} placeholder={'충전 금액'}
                       onChange={(e) => handleState(post, setPost, 'charge', Number(e.target.value))}/>
                <div className={"radio_box"}>
                  <label><input className={'radio_btn'} type="radio" name="theme" value="LIGHT"
                                checked={post.purchase_type === '1'} readOnly={true}
                                onClick={() => handleState(post, setPost, 'purchase_type', '1')}/>신용카드</label>
                  <label><input className={'radio_btn'} type="radio" name="theme" value="LIGHT"
                                checked={post.purchase_type === '5'} readOnly={true}
                                onClick={() => handleState(post, setPost, 'purchase_type', '5')}/>현금</label>
                  <label><input className={'radio_btn'} type="radio" name="theme" value="LIGHT"
                                checked={post.purchase_type === '7'} readOnly={true}
                                onClick={() => handleState(post, setPost, 'purchase_type', '7')}/>서비스</label>
                </div>
                <div className={'pointBtn'} onClick={() => handleCharge()}>충전하기</div>
              </div>
            </div>
            <div className={'contentContent'}>
              <div className={'contentTitle'}>
                <span style={{'marginRight': '20px'}}>사용내역</span>
                <div className={'cardInfo'}>
                  <div>총 충전금액</div>
                  <span>{handleNumber(detail['total_charge'] || 0)}원</span>
                  <div>총 사용금액</div>
                  <span>{handleNumber(detail['total_payment'] || 0)}원</span>
                </div>
                <div className={'excelContent'}>
                  <img src="/static/img/dashboard/ic_excel.png" alt=""/>
                  <div onClick={() => downloadExcel()}>내보내기</div>
                </div>
              </div>
              <Table className={"robot-table-title"} style={{textAlign: "center", fontFamily: "Spoqa Han Sans Neo",}}>
                <thead>
                <tr>
                  <th>일시</th>
                  <th>지점</th>
                  <th>
                    <select style={{'cursor': 'pointer'}} onChange={(e) => setPaymentState(e.target.value)}>
                      <option value={'0'}>거래유형</option>
                      <option value={'1'}>충전</option>
                      <option value={'2'}>사용</option>
                    </select>
                    {/*거래유형<img src="/static/img/dashboard/ic_sort.png" alt=""/>*/}
                  </th>
                  <th>결제수단</th>
                  <th>변동금액</th>
                  <th>잔액</th>
                  <th>관리자</th>
                  <th>비고</th>
                </tr>
                </thead>
                <tbody>
                {(allList || []).map((item, i) => {
                  return (
                    <tr key={"robot_" + i} onClick={(e) => {
                      // handleUpdate(store['branch_id']);
                    }}>
                      <td>{handleDateFormat('fullDate', item['crt_dttm'])}</td>
                      {/*<td>{store['brand_nm']}</td>*/}
                      <td>{item['branch_nm']}</td>
                      <td
                        style={item['payment_service'] === '충전' ? {'color': '#4664FF'} : {}}>{item['payment_service']}</td>
                      <td>{item['tool'] == null ? 'NONE' : item['tool']}</td>
                      <td>{handleNumber(item['purchase'])}원</td>
                      <td>{handleNumber(item['changes'])}원</td>
                      <td>{item['staff']}</td>
                      <td>
                        {item['payment_service'] === '사용' && i === 0 && page === 1 ? <Button
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                            borderColor: "#000",
                            width: '70px',
                            padding: '2px 0',
                            fontWeight: 500,
                            fontSize: '12px'
                          }}
                          onClick={() => handleCancel(item['id'], item['card_id'])}
                        >
                          사용취소
                        </Button> : ''}

                        {item['payment_service'] === '충전' && i === 0 && page === 1 ? <Button
                          style={{
                            backgroundColor: "#000",
                            color: "#fff",
                            borderColor: "#000",
                            width: '70px',
                            padding: '2px 0',
                            fontWeight: 500,
                            fontSize: '12px'
                          }}
                          onClick={() => handleCancel(item['id'], item['card_id'])}
                        >
                          충전취소
                        </Button> : ''}

                      </td>
                    </tr>
                  );
                })}
                </tbody>
              </Table>
              <PaginationComponent totalCount={totalCount} page={page} setPage={setPage}/>
            </div>

          </div>
          : null}
      </div>
    </>
  );
};
