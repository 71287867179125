import axios from "axios";
import {DEBUG_CONSOLE} from "./Constants";

/**
 *  @summary
 *  공통으로 사용하는 함수 정의
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 영역
/*################################################################################*/
/**
 *  @constant
 *  @type {Object}
 *  @description  http method type
 */
const METHOD_TYPE = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
  FORM: 'FORM',
};

/**
 *  @constant
 *  @type {number}
 *  @description  데이터 요청 타임아웃 시각
 */
const TIME_OUT: number = 30000;

/**
 *  @constant
 *  @type {string}
 *  @description  http 통신 요청 헤더에 token 을 위한 key 정보
 */
const HEADER_TOKEN_KEY: string = 'Authorization';

/**
 *  @constant
 *  @type {string}
 *  @description  http 통신 요청 헤더 사용하는 content-type
 */
const CONTENT_TYPE: string = 'Content-Type'

/*################################################################################*/
//## function 영역
/*################################################################################*/
/**
 *  @type      {function(*): *}
 *  @function  isDataExist - 데이터 존재 여뷰 체크
 *  @param     {Object} inData - 데이터 존재 체크 입력 변수
 *  @return     true  - 데이터 존재
 *              false - 데이터 null, undefined, space
 */
export const isDataExist = (inData :any) : boolean => {

  if (inData === null) return false;
  if (inData === undefined) return false;
  if (inData === '') return false;
  if (inData.length === 0) return false;

  return true;
};

/**
 *  @type      {function(*): *}
 *  @function  getAuthHeader - 인증 토큰을  검색해서  header 셋팅
 *  @param     {string} method - 데이터 존재 체크 입력 변수
 *  @return    {string} - axios 요청에 포함될 헤더 값 셋팅
 */
const getAuthHeader = (method: string, url:string) : string => {
  let token: any = 'Bearer ' + (url.includes('refresh') ? localStorage.getItem('refreshToken') : localStorage.getItem('accessToken'));
  // let token: any = 'Bearer ' + localStorage.getItem('accessToken');

  let header: any = {};

  switch (method) {

    /** get, delete type 일 경우  */
    case METHOD_TYPE.GET:
    case METHOD_TYPE.DELETE:
      header[HEADER_TOKEN_KEY] = token;
      break;

    /** post, put 타입일 경우     */
    case METHOD_TYPE.POST:
    case METHOD_TYPE.PUT:
      header[HEADER_TOKEN_KEY] = token;
      header[CONTENT_TYPE] = 'application/json';
      break;

    /**  form type 일 경우       */
    case METHOD_TYPE.FORM:
      header[HEADER_TOKEN_KEY] = token;
      break;

    default:
      break;
  }

  return header;
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_GET - header 가 셋팅 된 axios get 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_GET = (url: string, timeOut: number = TIME_OUT) => {

  if (DEBUG_CONSOLE) {
    console.log(`=======AXIOS_GET========`);
    console.log(url);
  }

  const header: any = getAuthHeader(METHOD_TYPE.GET, url);

  return axios.get(url, {
    // params: {
    //   robot_class: 1
    // },
    headers: header,
    timeout: timeOut
  });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_GET - header 가 셋팅 된 axios get 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_GET_BLOB = (url: any, timeOut: number = TIME_OUT, responseType: any) => {

  if (DEBUG_CONSOLE) {
    console.log(`=======AXIOS_GET========`);
    console.log(url);
  }

  const header: any = getAuthHeader(METHOD_TYPE.GET, url);

  return axios.get(url, {
    headers: header,
    timeout: timeOut,
    responseType: responseType
  });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_DELETE - header 가 셋팅 된 axios delete 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_DELETE = (url: string) => {

  const header: any = getAuthHeader(METHOD_TYPE.DELETE, url);

  return axios.delete(url, {
    headers: header,
    timeout: TIME_OUT
  });
};


/**
 *  @type      {function(*): *}
 *  @function  AXIOS_POST - header, send data 가 셋팅 된 axios post 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_POST = (url: string, sendString: string = '', timeOut: number = TIME_OUT) => {

  const header: any = getAuthHeader(METHOD_TYPE.POST, url);

  return axios.post(url, sendString, {
    headers: header,
    timeout: timeOut,
  });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_POST_FORM - header, send data 가 셋팅 된 axios post를 통해  form 데이터 전송
 *  @param     {string} url - 요청 URL
 *  @param     {Object} sendObject - 전송 form data
 */
export const AXIOS_POST_FORM = (url: string, sendObject: any, timeOut: number = TIME_OUT) => {

  const header: any = getAuthHeader(METHOD_TYPE.FORM, url);

  return axios.post(url, sendObject, {
    headers: header,
    timeout: timeOut
  });
};


/**
 *  @type      {function(*): *}
 *  @function  AXIOS_PUT_FORM - header, send data 가 셋팅 된 axios post를 통해  form 데이터 전송
 *  @param     {string} url - 요청 URL
 *  @param     {Object} sendObject - 전송 form data
 */
export const AXIOS_PUT_FORM = (url: string, sendObject: any) => {

  const header: any = getAuthHeader(METHOD_TYPE.FORM, url);

  return axios.put(url, sendObject, {
    headers: header,
    timeout: TIME_OUT
  });
};

/**
 *  @type      {function(string, string): Promise<AxiosResponse<T>>}
 *  @function  AXIOS_PUT - header, send data 가 셋팅 된 axios put 요청
 *  @param     {string} url - 요청 URL
 *  @param     {string} sendString - 업데이터 정보
 */
export const AXIOS_PUT = (url: string, sendString: string = '') => {

  const header: any = getAuthHeader(METHOD_TYPE.PUT, url);

  if (DEBUG_CONSOLE) {
    console.log(`=======AXIOS_PUT========`);
    console.log(url);
  }

  return axios.put(url, sendString, {
    headers: header,
    timeout: TIME_OUT
  });
};

/**
 *  @type      {function(*): *}
 *  @function  AXIOS_DOWNLOAD - header 가 셋팅 된 axios get 요청
 *  @param     {string} url - 요청 URL
 */
export const AXIOS_DOWNLOAD = (url: string) => {

  if (DEBUG_CONSOLE) {
    console.log(`=======AXIOS_GET========`);
    console.log(url);
  }

  const header: any = getAuthHeader(METHOD_TYPE.GET, url);

  return axios.get(url, {
    headers: header,
    timeout: TIME_OUT,
    responseType: 'blob',
  });
};
