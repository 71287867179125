import moment from 'moment';
import 'moment/locale/ko'
const KR_TIME_DIFF = 9 * 60 * 60 * 1000;

// 날짜 형식
export const handleDateFormat = (format, value) => {
  let newDate = '';

  switch(format) {
    case 'date':
      newDate = moment(value).format('YYYY-MM-DD');
      break;
    case 'fullDate':
      newDate = moment(value).format('YYYY-MM-DD HH:mm:ss');
      break;
    case 'string':
      newDate = moment(value).format('YYYYMMDD');
      break;
    default:
      break;
  }

  return newDate;
}
