import React, {useCallback, useEffect, useState} from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactConfirmAlert from "react-confirm-alert";
import {Button, Card, CardBody, CardFooter, CardHeader, Row} from "reactstrap";
import {DEBUG_CONSOLE, ERROR_CODE, LOGIN_URL} from "./Constants";
import {useNavigate} from "react-router-dom";

/**
 *  @summary
 *  Error Modal 창 컴포넌트
 *  UI의 event 발생으로 상위 컴포넌트로 이벤트 전달
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */
const ErrorModal = (props: any) => {
  const navigate = useNavigate();

  /*################################################################################*/
  //## data 영역
  //##  - props, state
  /*################################################################################*/
  /*
  *   상위 컴포넌트에서 전달 받은 props
  *   1. onRetry      : 재시도 이벤트 전달
  *   2. onClose      : 닫기 버튼 이벤트 전달
  *   3. errorInfo    : 에러 정보 메시지
  *   4. errorCode    : 에러 코드
  *   5. history      : url 이동을 위한 history 객체
  */
  const { onRetry, onClose, errorInfo, errorCode, history } = props;
  // Error 메시지
  const [errorMsg, setErrorMsg] = useState<string | null>( '');

  /*################################################################################*/
  //## function define 영역
  //## - useCallback
  /*################################################################################*/
  /**
   *  @function  handleRetry
   *  @description  retry 버튼을 클릭 시 처리 함수
   */
  const handleRetry = useCallback(() => {
    if(onRetry !== undefined){
      onRetry(errorInfo);
    }
  },[onRetry, errorInfo]);

  /**
   *  @function  handleCancel
   *  @param  {number} code- Error Code
   *  @description  cancel 버튼 클릭 시 처리 함수
   */
  const handleCancel = useCallback((code: any) => {
    if(DEBUG_CONSOLE) {
      console.log(code);
    }
    if(onClose !== undefined){
      onClose(code);
    }
  },[onClose]);

  /**
   *  @function  handleLogin
   *  @param  {number} code- Error Code
   *  @description  login 버튼 클릭 시 처리 함수
   */
  const handleLogin = useCallback((code: any) => {
    if(DEBUG_CONSOLE) {
      console.log(code);
    }
    if(onClose !== undefined){
      localStorage.removeItem('accessToken');
      // navigate('/login');
      window.location.reload();
    }
  },[onClose]);

  /**
   *  @function  focusOnEnter
   *  @param  {number} code- Error Code
   *  @description  enter 키 focus 를 위한 함수
   */
  const focusOnEnter = useCallback(()=>{
    if(DEBUG_CONSOLE) {
      console.log(document.getElementById('cancelButton'));
    }
    // @ts-ignore
    document.getElementById('cancelButton').focus();
  },[]);


  /**
   *  @function  goLogin
   *  @description  로그인 화면 으로 이동할 때
   */
  const goLogin = useCallback(() => {
    handleCancel(ERROR_CODE.AUTH_ERROR);
    // history.push(LOGIN_URL);
    navigate(LOGIN_URL);
  },[handleCancel, history]);


  /**
   *  @function  getFooterButton
   *  @description  error code 에 따른 하단 버튼 부분 표시
   */
  const getFooterButton = useCallback(()=>{
    switch (errorCode) {
      case ERROR_CODE.AUTH_ERROR:
      case ERROR_CODE.NO_USER:
      case ERROR_CODE.NO_TOKEN :
      case ERROR_CODE.TOKEN_EXPIRED :
        return(
          <>
            <div className='d-flex justify-content-end'>
              <Button
                color="danger"
                id="cancelButton"
                className="btn-inner-shadow text-white px-5"
                onClick={handleLogin}
              >
                LOGIN
              </Button>
            </div>
          </>);
      case ERROR_CODE.NO_DATA :
        return(
          <>
            <div className='d-flex justify-content-end'>
              <Button
                color="danger"
                id="cancelButton"
                className="btn-inner-shadow text-white px-5"
                onClick={handleLogin}
              >
                LOGIN
              </Button>
            </div>
          </>);
      case ERROR_CODE.VERIFY_FAIL:
        return(
          <>
            <div className='d-flex justify-content-end'>
              <Button
                color="danger"
                id="cancelButton"
                className="btn-inner-shadow text-white px-5"
                onClick={goLogin}
              >
                OK
              </Button>
            </div>
          </>);
      case ERROR_CODE.LOCK_AUTH_FAIL :
        return(
          <>
            <div className='d-flex justify-content-end'>
              <Button
                color="danger"
                id="cancelButton"
                className="btn-inner-shadow text-white px-5"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </>);
      default:
        return (
          <>
            <div className='d-flex justify-content-end'>
              <Button
                color="danger"
                className="btn-inner-shadow text-white px-5 mx-3"
                onClick={handleRetry}
              >
                Retry
              </Button>
              <Button
                color="primary"
                id="cancelButton"
                className="btn-inner-shadow text-white px-3 px-md-5"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </>
        );
    }
  },[errorCode, goLogin, handleCancel, handleRetry]);

  /*################################################################################*/
  //## rerender effect 영역
  //## - useEffect
  /*################################################################################*/
  /**
   *  @listens  [] - 최초 실행 시
   *  @description  - 에러 메시지 설정
   */
  useEffect(()=>{
    if(errorInfo === null){
      setErrorMsg(null);
    }
    else{
      setErrorMsg(errorInfo);
    }
  },[errorInfo]);

  /**
   *  @listens  [] - 최초 실행 시
   *  @description  - 취소 버튼에 focus
   */
  useEffect(()=>{
    focusOnEnter();
  },[focusOnEnter]);

  /*################################################################################*/
  //## component view 영역
  //## - JSX return
  /*################################################################################*/
  return (
    <ReactConfirmAlert
      customUI={() => {
        return (
          <>
            <Card className="popup_min_width popup_max_width border-1 shadow-lg rounded-lg mb-0" size="xl">
              <CardHeader className="bg-danger">
                <div className="d-flex align-items-center justify-content-start">
                  <i className="fa fa-info-circle" />
                  <div className="mx-2">
                    <strong>Error</strong>
                  </div>
                </div>
              </CardHeader>
              <CardBody className="popup_min_height py-4 mx-3">
                {/*<Row>*/}
                  <h5 className="card-title">{errorMsg}</h5>
                {/*</Row>*/}
              </CardBody>
              <CardFooter >
                {getFooterButton()}
              </CardFooter>
            </Card>
          </>
        );
      }}
    />
  );
};

export default React.memo(ErrorModal);
