import React, {useEffect, useState, useCallback, useRef} from "react";

// CSS
import '../index.scss'
import {Form, Row} from "react-bootstrap";

// Common
import {handleDateFormat} from "../common/moment";

// Network
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST, AXIOS_PUT} from "../../common/Network/CommonFunction";
import {Col} from "reactstrap";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command, params) {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    // 데이터 생성 요청
    case COMMAND.DATA_CREATE : {
      const {requestUrl, sendString} = params;

      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    // 데이터 수정 요청
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;

      response = await AXIOS_PUT(requestUrl, JSON.stringify(sendString));
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const StoreDetail = ({...props}) => {
  const {history} = props;
  const [detail, setDetail] = useState({});
  const [date, setDate] = useState(['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']);

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action, data) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        setDetail(data.payload.branch);
      }
      if (command === COMMAND.DATA_CREATE || command === COMMAND.DATA_UPDATE) {
        // alert('저장되었습니다.');
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (props.branchId !== 'new') {
      const command = COMMAND.DATA_INFO;

      const params = {
        requestUrl: process.env.REACT_APP_API_HOST + '/branches/' + props.branchId
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      setDetail(
        {
          'branch_id': '',
          'brand_nm': '',
          'branch_nm_ko': '',
          'branch_nm_en': '',
          'address': '',
          'tel_no': '',
          'repr': '',
          'biz_no': '',
          'TID': '',
          'coord_x': '',
          'coord_y': '',
          'active_yn': 1,
          'visible': 1,
          'open_date': '',
          'close_date': '',
          'sun': 1,
          'mon': 1,
          'tue': 1,
          'wed': 1,
          'thu': 1,
          'fri': 1,
          'sat': 1,
        });
    }
  }, [props.branchId]);

  const handleSave = (data) => {
    const tempParams = {
      'branch_id': detail['branch_id'],
      'brand_nm': detail['brand_nm'],
      'branch_nm_ko': detail['branch_nm_ko'],
      'branch_nm_en': detail['branch_nm_en'],
      'address': detail['address'],
      'tel_no': detail['tel_no'],
      'repr': detail['repr'],
      'biz_no': detail['biz_no'],
      'TID': detail['TID'],
      'coord_x': Number(detail['coord_x']),
      'coord_y': Number(detail['coord_y']),
      'active_yn': detail['active_yn'],
      'open_date': detail['open_date'],
      'close_date': detail['close_date'],
      'visible': detail['visible'],
      'sun': Number(detail['sun']),
      'mon': Number(detail['mon']),
      'tue': Number(detail['tue']),
      'wed': Number(detail['wed']),
      'thu': Number(detail['thu']),
      'fri': Number(detail['fri']),
      'sat': Number(detail['sat']),
    };

    if (props.branchId === 'new') { // 저장
      const command = COMMAND.DATA_CREATE;

      const params = {
        sendString: tempParams,
        requestUrl: process.env.REACT_APP_API_HOST + '/branches'
      };

      /** back-end 데이터 처리 요청 */
      netWorkAgent.current.request(command, params);
    } else {
      // 수정 API 호출
      const command = COMMAND.DATA_UPDATE;

      const params = {
        sendString: tempParams,
        requestUrl: process.env.REACT_APP_API_HOST + '/branches/' + props.branchId
      };

      /** back-end 데이터 처리 요청. */
      netWorkAgent.current.request(command, params);
    }
  }

  const handleState = (state, value) => {
    let tempState = JSON.parse(JSON.stringify(detail));
    tempState[state] = value;
    setDetail(tempState);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"subTitleItem"}>상세 정보</div>
      <Form noValidate>

        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom01">
            <Form.Label>매장 아이디</Form.Label>
            <Form.Control
              required
              readOnly={props.branchId !== 'new'}
              type="text"
              placeholder="매장 아이디"
              onChange={(e) => handleState('branch_id', e.target.value)}
              value={(detail && detail['branch_id']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>브랜드 명</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="브랜드 명"
              onChange={(e) => handleState('brand_nm', e.target.value)}
              value={(detail && detail['brand_nm']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>매장명(한글)</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="매장명(한글)"
              onChange={(e) => handleState('branch_nm_ko', e.target.value)}
              value={(detail && detail['branch_nm_ko']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>매장명(영문)</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="매장명(영문)"
              onChange={(e) => handleState('branch_nm_en', e.target.value)}
              value={(detail && detail['branch_nm_en']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>주소</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="주소"
              onChange={(e) => handleState('address', e.target.value)}
              value={(detail && detail['address']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>위도</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="위도"
              onChange={(e) => handleState('coord_x', e.target.value)}
              value={(detail && detail['coord_x']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>경도</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="경도"
              onChange={(e) => handleState('coord_y', e.target.value)}
              value={(detail && detail['coord_y']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>전화번호</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="전화번호"
              onChange={(e) => handleState('tel_no', e.target.value)}
              value={(detail && detail['tel_no']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>대표자명</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="대표자명"
              onChange={(e) => handleState('repr', e.target.value)}
              value={(detail && detail['repr']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>사업자 등록번호</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="사업자 등록번호"
              onChange={(e) => handleState('biz_no', e.target.value)}
              value={(detail && detail['biz_no']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>터미널 아이디</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="터미널 아이디"
              onChange={(e) => handleState('TID', e.target.value)}
              value={(detail && detail['TID']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>활성화 여부</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 1)}
                            checked={detail && detail['active_yn'] === 1}/>활성화</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('active_yn', 0)}
                            checked={detail && detail['active_yn'] === 0}/>비활성화</label>
            </div>
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>그래프 노출 여부</Form.Label>
            <div className={"radio_box"}>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('visible', 1)}
                            checked={detail && detail['visible'] === 1}/>노출</label>
              <label><input className={'radio_btn'} type="radio"
                            onChange={() => handleState('visible', 0)}
                            checked={detail && detail['visible'] === 0}/>노출 X</label>
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="6" controlId="validationCustom02">
            <Form.Label>오픈일</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="YYYYMMDD"
              onChange={(e) => handleState('open_date', e.target.value)}
              value={(detail && detail['open_date']) || ''}
            />
          </Form.Group>
          <Form.Group as={Col} md="6" controlId="validationCustom01">
            <Form.Label>폐업일</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="YYYYMMDD"
              onChange={(e) => handleState('close_date', e.target.value)}
              value={(detail && detail['close_date']) || ''}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3">
          <Form.Group as={Col} md="12" controlId="validationCustom02">
            <Form.Label>휴무일</Form.Label>
            <div key={`inline-checkbox`} className="mb-3">
              {(date).map((item, index) => {
                return (
                  <Form.Check
                    inline
                    key={index}
                    label={item}
                    name="group1"
                    type={'checkbox'}
                    onChange={(e) => handleState(item, !detail[item])}
                    checked={detail[item] == 0}
                    id={`inline-checkbox-${index}`}
                  />
                )
              })}
            </div>
          </Form.Group>
        </Row>
        {props.branchId !== 'new' ?
          <>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>최초 생성 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 생성 아이디"
                  value={(detail && detail['crt_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>최초 생성 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="최초 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['crt_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="validationCustom01">
                <Form.Label>마지막 수정 아이디</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 아이디"
                  value={(detail && detail['upd_id']) || '-'}
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="validationCustom02">
                <Form.Label>마지막 수정 날짜</Form.Label>
                <Form.Control
                  required
                  type="text"
                  readOnly={true}
                  placeholder="마지막 수정 날짜"
                  value={(detail && handleDateFormat('fullDate', detail['upd_dttm'])) || '-'}
                />
              </Form.Group>
            </Row>
          </>
          : null}
      </Form>

      <div className={"buttons"}>
        <div className={'save_btn'} onClick={(e) => handleSave(e)}>{props.branchId === 'new' ? '저장' : '수정'}</div>
      </div>
    </>
  );
};
