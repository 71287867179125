import {USER_INFO} from '../constants/ActionTypes'

export const loginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_INFO:
      return { ...state, loginSuccess: action.payload };
    default:
      return state;
  }
}
