import React, {CSSProperties} from "react";
import {HashLoader} from "react-spinners";

/**
 *  @summary
 *  back-end 통신 등의 처리 시 Indicator 표시 컴포넌트
 *
 *  @author  김정현
 *  @version 1.0, 작업 내용
 *  @see None
 */

/*################################################################################*/
//## constant 영역
/*################################################################################*/
/**
 *  @constant
 *  @type {CSSProperties}
 *  @description  Indicator 를 표시하는 상위 div 스타일
 */
const INDICATOR_STYLE: CSSProperties = {
  textAlign: "center",
  padding: "padding: 1em 2em 0 0",
  left: "50%",
  top: "50%",
  zIndex: "9999",
  position: "absolute",
};

/**
 *  @constant
 *  @type {string}
 *  @description Indicator 에 사용되는 색상 정보
 */
const INDICATOR_COLOR: string = "#4A90E2";

/**
 *  @type      {function(*): *}
 *  @function  Indicator 표시 함수형 컴포넌트
 *  @param     {Object} props - 상위 컴포넌트에서 전달 받은 property
 */
const LoadingIndicator = (props: any) => {

  /*################################################################################*/
  //## data 영역
  //##  - props, state
  /*################################################################################*/
  /*
  *   상위 컴포넌트에서 전달 받은 props
  *   1. loading      : 현재 loading 여부 정보
  */
  const { loading } = props;

  /*################################################################################*/
  //## component view 영역
  //## - JSX return
  /*################################################################################*/
  return (
    <div style={INDICATOR_STYLE}>
      <HashLoader color={INDICATOR_COLOR} loading={loading} />
    </div>
  );
};

export default LoadingIndicator;
