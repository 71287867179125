import React, {useCallback, useEffect, useRef, useState} from "react";
import {Form, Row, Col} from "react-bootstrap";

// CSS
import './index.scss';
import '../components/css/Table.scss'
import {Button, Table} from "react-bootstrap";

// Components
import {PaginationComponent} from "../components/Pagination";
import {ReviewDetail} from "../components/Detail/ReviewDetail";
import Modal from 'react-bootstrap/Modal';


// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_DOWNLOAD, AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";
import {DatePicker} from "../components/DatePicker";
import {handleDateFormat} from "../components/common/moment";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    case 'DOWNLOAD' : {
      const {requestUrl} = params;
      response = await AXIOS_DOWNLOAD(requestUrl);
    }
      break;
    default:
      return null;
  }

  return response.data;
}

export const Review: React.FC = (props: any) => {
  const [reviewList, setReviewList] = useState([]);
  const [reviewId, setReviewId] = useState({
    branch_id: '',
    ord_no: '',
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [branchList, setBranchList] = useState<any>([]);
  const [date, setDate] = useState<any>('');
  const [branch, setBranch] = useState<string>('');
  const [stats, setStats] = useState<any>([]);
  const [order, setOrder] = useState<any>({
    'qty': '',
    'recommend_rating': '',
    'taste_rating': '',
    'speed_rating': '',
    'crt_dttm': 'DESC',
    'comment': ''
  });

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        if(action['params']['requestUrl'].indexOf('/reviews/review/meta/branch') >= 0){
          setBranchList(data.payload);
        }
        if(action['params']['requestUrl'].indexOf('/reviews/list') >= 0) {
          for (let re of data.payload.list) {
            Object.assign(re, {'reg_dt' : re['reg_dt'].slice(0,4) + '-' + re['reg_dt'].slice(4, 6) + '-' + re['reg_dt'].slice(6,8)})
            Object.assign(re, {'reg_tm' : re['reg_tm'].slice(0,2) + ':' + re['reg_tm'].slice(2, 4)})
          }
          setStats(data.payload.stats);
          setReviewList(data.payload.list);
          setTotalCount(Number(data.payload.meta.itemCount));
        }
      }
      if (command === 'DOWNLOAD'){
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `review_${handleDateFormat('date', Date.now())}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    if (page) {
      handleSearch(page);
    }
  }, [page, order])

  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/reviews/review/meta/branch'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  const handleSearch = (data: number) => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/reviews/list?take=10&page=' + data
    };

    if(date) {
      let temp = params.requestUrl + '&reg_dt=' + handleDateFormat('string', date);
      Object.assign(params, {requestUrl: temp});
    }
    if(branch) {
      let temp = params.requestUrl + '&branch_id=' + branch
      Object.assign(params, {requestUrl: temp});
    }
    if(order) {
      for(let item of Object.keys(order)){
        if(order[item] !== ''){
          let temp = params.requestUrl + '&orderField=' + item + '&order=' + order[item]
          Object.assign(params, {requestUrl: temp});
          break;
        }
      }
    }

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const handleDate = (state: string, value: Date) => {
    setDate(value)
  }

  const handleSort = (state:string) => {
    const sort = (order[state] === 'ASC' ? 'DESC' : order[state] === 'DESC' ? '' : 'ASC');

    let tempState = JSON.parse(JSON.stringify(order));
    for(let item of Object.keys(order)) {
      tempState[item] = '';
    }
    tempState[state] = sort;
    setOrder(tempState);
  }

  const downloadExcel = () => {
    const command = 'DOWNLOAD';
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/reviews/download?'
    };

    if(date) {
      let temp = params.requestUrl + '&reg_dt=' + handleDateFormat('string', date);
      Object.assign(params, {requestUrl: temp});
    }
    if(branch) {
      let temp = params.requestUrl + '&branch_id=' + branch
      Object.assign(params, {requestUrl: temp});
    }

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'reviewContainer'}>
        <div className={'reviewOverview'}>
          {(stats || []).map((item:any, index:number) => {
            return(
              <div className={'reviewItem'} key={index}>
                <div className={'reviewTitle'}>
                  {item['branch_nm_ko']} ({item['branch_id']})
                </div>
                <div className={'reviewContent'}>
                  <div className={'reviewLabel'}>추천 점수<span>{Number(item['avg_recommend_rating']).toFixed(2)}</span></div>
                  <div className={'reviewLabel'}>속도 점수<span>{Number(item['avg_speed_rating']).toFixed(2)}</span></div>
                  <div className={'reviewLabel'}>맛 점수<span>{Number(item['avg_taste_rating']).toFixed(2)}</span></div>
                </div>
              </div>
            )
          })}
        </div>

        <div className={'reviewSearch'}>
          <Form>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                지점
              </Form.Label>
              <Col sm={4}>
                <Form.Select aria-label="Default select example" onChange={(e) => setBranch(e.target.value)}>
                  <option value={''}>== 전체 ===</option>
                  {(branchList || []).map((item:any, index:number) => {
                    return (
                      <option value={item['branch_id']} key={index}>{item['branch_nm_ko']}</option>
                    )
                  })}
                </Form.Select>
              </Col>
              <Form.Label column sm={2}>
                등록일자
              </Form.Label>
              <Col sm={4}>
                <DatePicker period={'self'} date={date} state={'end'} handleDate={handleDate}/>
              </Col>
            </Form.Group>
          </Form>
        </div>

        <div className={"buttons"} style={{'marginBottom': '30px'}}>
          <div className={"cancel_btn"} onClick={() => window.location.reload()}>초기화</div>
          <div className={'save_btn'} onClick={() => handleSearch(1)}>검색</div>
        </div>
        <div className={'excel'}>
          <div className={'total'}>전체 조회 수: <span>{totalCount || 0}</span></div>
          <div className={'download'} onClick={() => downloadExcel()}>
            <img src="/static/img/dashboard/ic_excel.png" alt=""/>
            검색결과 다운로드
          </div>
        </div>
        <div className={'reviewContent'}>
          <Table striped
            className={"robot-table-title"}
            style={{
              textAlign: "center",
              fontFamily: "Spoqa Han Sans Neo",
            }}
          >
            <thead>
            <tr>
              {windowWidth < 768 ?
                <>
                  <th>매장명</th>
                  <th onClick={() => handleSort('recommend_rating')}>추천 {order['recommend_rating'] === 'ASC' ? '↑' : order['recommend_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('taste_rating')}>맛 {order['taste_rating'] === 'ASC' ? '↑' : order['taste_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('speed_rating')}>속도 {order['speed_rating'] === 'ASC' ? '↑' : order['speed_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('comment')}>코멘트 {order['comment'] === 'ASC' ? '↑' : order['comment'] === 'DESC' ? '↓' : ''}</th>
                </> :
                <>
                  <th style={{'width': '250px'}}>매장명</th>
                  <th>주문번호</th>
                  <th onClick={() => handleSort('operate_time')}>소요시간 {order['operate_time'] === 'ASC' ? '↑' : order['operate_time'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('qty')}>주문수량 {order['qty'] === 'ASC' ? '↑' : order['qty'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('recommend_rating')}>추천 {order['recommend_rating'] === 'ASC' ? '↑' : order['recommend_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('taste_rating')}>맛 {order['taste_rating'] === 'ASC' ? '↑' : order['taste_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('speed_rating')}>속도 {order['speed_rating'] === 'ASC' ? '↑' : order['speed_rating'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('comment')}>코멘트 {order['comment'] === 'ASC' ? '↑' : order['comment'] === 'DESC' ? '↓' : ''}</th>
                  <th onClick={() => handleSort('crt_dttm')}>등록일시 {order['crt_dttm'] === 'ASC' ? '↑' : order['crt_dttm'] === 'DESC' ? '↓' : ''}</th>
                </>}

            </tr>
            </thead>
            <tbody>
            {(reviewList || []).map((review: any, i: number) => {
              return (
                <tr key={"robot_" + i} onClick={() => setReviewId({
                  branch_id: review['branch_id'],
                  ord_no: review['ord_no'],
                })}>
                  {windowWidth < 768 ?
                    <>
                      <td>{review['branch_nm_ko']}</td>
                      <td
                        style={{'color': review['recommend_rating'] >= 4 ? 'blue' : review['recommend_rating'] <= 2 ? 'red' : 'null'}}>{review['recommend_rating']}</td>
                      <td
                        style={{'color': review['taste_rating'] >= 4 ? 'blue' : review['taste_rating'] <= 2 ? 'red' : 'null'}}>{review['taste_rating']}</td>
                      <td
                        style={{'color': review['speed_rating'] >= 4 ? 'blue' : review['speed_rating'] <= 2 ? 'red' : 'null'}}>{review['speed_rating']}</td>
                      <td>{review['comment'] ? 'Y' : 'N'}</td>
                    </> : <>
                      <td>{review['branch_nm_ko']}</td>
                      <td>{review['ord_no']}</td>
                      <td>{review['operate_time']}</td>
                      <td>{review['qty']} 개</td>
                      <td
                        style={{'color': review['recommend_rating'] >= 4 ? 'blue' : review['recommend_rating'] <= 2 ? 'red' : 'null'}}>{review['recommend_rating']}</td>
                      <td
                        style={{'color': review['taste_rating'] >= 4 ? 'blue' : review['taste_rating'] <= 2 ? 'red' : 'null'}}>{review['taste_rating']}</td>
                      <td
                        style={{'color': review['speed_rating'] >= 4 ? 'blue' : review['speed_rating'] <= 2 ? 'red' : 'null'}}>{review['speed_rating']}</td>
                      <td>{review['comment'] ? 'Y' : 'N'}</td>
                      <td>{review['reg_dt']} {review['reg_tm']}</td>
                    </>}
                  <div className={'tooltip'}>{review['comment']}</div>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </div>
        <PaginationComponent totalCount={totalCount} page={page} setPage={setPage}/>
        {reviewId?.branch_id ? <ReviewDetail reviewId={reviewId}/> : null}
      </div>
    </>
  );
};
