import React, {useCallback, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { registerUser } from "../redux/actions";

// CSS
import "../components/css/LogIn.scss";

// Network
import {AXIOS_GET, AXIOS_POST} from "../common/Network/CommonFunction";
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO :
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
      break;

    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const LogIn: React.FC = (props: any) => {
  const navigate = useNavigate();
  const [detail, setDetail] = useState({
    "employee_id": '',
    "password": ''
  });
  const dispatch = useDispatch();

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {

      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        //session storage에 token 값 저장
        if(data.code === 200) {
          localStorage.setItem("accessToken", data.payload.accessToken);
          localStorage.setItem("refreshToken", data.payload.refreshToken);

          dispatch(registerUser(data));

          const command = COMMAND.DATA_LIST;
          const params = {
            requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/menu'
          };

          /** back-end 데이터 처리 요청 */
          netWorkAgent.current.request(command, params);
        }
      }
      // // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        //session storage에 token 값 저장
        if(data.code === 200) {
          for(let item of data.payload) {
            if(item.rights !== 'N') {
              navigate('/pointcard');
              return;
            }
          }
        }
      }
    }
  }, [dispatch, navigate]);

  const handleSubmit = () => {
    const command = COMMAND.DATA_INFO;

    const params = {
      sendString: {
        "employee_id": detail['employee_id'],
        "password": detail['password']
      },
      requestUrl: process.env.REACT_APP_API_HOST + '/auth/login'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  };

  const handleState = (state:string, value:string) => {
    let tempState = JSON.parse(JSON.stringify(detail));
    tempState[state] = value;
    setDetail(tempState);
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"authContainer"}>
        <div className={"authWrapper"}>
          <div className={"authInner"}>
            <h3>Sign In</h3>
            <div className="form-group">
              <label>Username</label>
              <input
                type="text"
                className="form-control"
                name={"employee_id"}
                placeholder="Enter username"
                value={detail.employee_id}
                onChange={(e) => handleState('employee_id', e.target.value)}
                autoFocus={true}
              />
            </div>

            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                className="form-control"
                name={"password"}
                placeholder="Enter password"
                onChange={(e) => handleState('password', e.target.value)}
                value={detail.password}
                onKeyUp={(e) => e.keyCode === 13 ? handleSubmit() : null}
              />
            </div>
            <div className="btn btn-primary btn-block" style={{'backgroundColor': 'black', 'borderColor': 'black'}} onClick={() => handleSubmit()}>Submit</div>
          </div>
        </div>
      </div>
    </>
  );
};
