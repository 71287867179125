import React, {useEffect, useState} from "react";
import {ResponsiveBar, Bar} from "@nivo/bar";

// CSS
import './css/CardItem.scss'

export const BarChart = ({...props}) => {
  const [keyValue, setKeyValue] = useState([]);
  const [color, setColor] = useState([]);
  const [axisX, setAxisX] = useState([]);
  const [data, setData] = useState([]);
  const [unit, setUnit] = useState('');


  useEffect(() => {
    setColor(['#41c38f', '#25bcde', '#4664ff', '#1f2d80', '#ffcc46', '#ff9e45', '#f3546a', '#6C4AB6']);
  }, []);

  useEffect(() => {
    if (props.data) {
      let tempState = [];
      if (props.data.length > 0) {
        tempState = Object.keys(props.data[0]);
        const idx = tempState.indexOf('date');
        if (idx > -1) tempState.splice(idx, 1)

        setKeyValue(tempState);

        let axisIdx = 0;
        let tempAxis = [];
        for(const item of props.data){
          if(axisIdx % 5 === 0 || item['date'].indexOf('시') > 0) {
            tempAxis.push(item['date']);
          }
          axisIdx++;
        }

        setAxisX(tempAxis);
        setData(props.data);
        if(Object.keys(props.data[0])[1].indexOf('바리스') >= 0) setUnit('잔');
        if(Object.keys(props.data[0])[1].indexOf('아리스') >= 0) setUnit('개');
      }
    }
  }, [props.data])

  return (
    <ResponsiveBar
      data={data}
      // width={props.width || 500}
      height={props.height || props.width < 768 ? 450 : (props.page === 'HOME' ? 500 : 500)}
      // height={props.height || props.page === 'HOME' ? 500 : 430}
      keys={keyValue}
      indexBy="date"
      margin={{top: 10, right: props.width < 768 ? 10 : 200, bottom: 180, left: 40}}
      padding={0.3}
      colors={color}
      axisTop={null}
      axisRight={null}
      enableLabel={false}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32,
        tickValues: props.page === 'HOME' ? '' : axisX
      }}
      tooltip={({id, value, color,index,indexValue }) => (
        <div
          style={{
            padding: 5,
            color,
            background: '#ffffff',
          }}
        >
          <strong>
            {id}: {value.toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {unit}
          </strong>
        </div>
      )}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: -40
      }}
      legends={[
        {
          dataFrom: 'keys',
          anchor: props.width < 768 ? (props.page === 'HOME' ? 'bottom' : 'bottom-left') : 'bottom-right',
          direction: props.width < 768 && props.page === 'HOME' ? 'row' : 'column',
          justify: false,
          translateX: 60,
          translateY: props.width < 768 ? (props.page === 'HOME' ? 70 : 170) : 0,
          itemsSpacing: 2,
          itemWidth: props.width < 768 ? 100 : 50,
          itemHeight: 15,
          itemDirection: 'left-to-right',
          itemOpacity: 0.75,
          symbolSize: 12,
        }
      ]}
      role="application"
      ariaLabel="Nivo bar chart demo"
      animate={true}
    />
  );
};
