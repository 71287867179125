import React, {useEffect} from 'react';
import {createBrowserHistory} from 'history';
import {Route, Routes, useNavigate} from "react-router-dom";
import {Menu} from "./components/layout/Menu";
import {Header} from "./components/layout/Header";
import {Home} from "./containers/Home";
import {Baris} from "./containers/Baris";
import {Aris} from "./containers/Aris";
import {Robot} from "./containers/Robot";
import {Store} from "./containers/Store";
import {Point} from "./containers/Point";
import {Settings} from "./containers/Settings";
import {Review} from "./containers/Review";
import {LogIn} from "./containers/LogIn";

const App = () => {
  const history = createBrowserHistory();
  const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');

  useEffect(() => {
    if (token && history.location.pathname === '/login') {
      if(window.location.host.includes('pointcard') || window.location.host.includes('localhost')) navigate('/pointcard');
      else navigate('/pointcard');
      return;
    }

    if (!token) {
      navigate('/login');
    }
  }, [history.location.pathname, navigate, token]);

  return (
    <div className="App">
      {token ?
        <>
          <Menu/>
          <Header/>
          <Routes>
            <Route path={'/'} element={<Home/>}/>
            <Route path={'/baris'} element={<Baris/>}/>
            <Route path={'/aris'} element={<Aris/>}/>
            <Route path={'/robot'} element={<Robot/>}/>
            <Route path={'/store'} element={<Store/>}/>
            <Route path={'/pointcard'} element={<Point/>}/>
            <Route path={'/settings'} element={<Settings/>}/>
            <Route path={'/review'} element={<Review/>}/>
            <Route path={'/login'} element={<LogIn/>}/>
          </Routes>
        </> :
        <Routes>
          <Route path={'/login'} element={<LogIn/>}/>
        </Routes>
      }
    </div>
  );
}

export default App;
