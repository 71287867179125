import React, {useEffect, useState} from "react";
import {ResponsiveLine} from "@nivo/line";
import {area} from "d3-shape";
import {Defs} from "@nivo/core";

export const LineChart = ({...props}) => {
  const [color, setColor] = useState([]);
  const [axisX, setAxisX] = useState([]);
  const [data, setData] = useState([]);
  const [unit, setUnit] = useState('');
  const [theme, setTheme] = useState({
    // background: "#222222",
    // axis: {
    //   fontSize: "14px",
    //   tickColor: "#eee",
    //   ticks: {
    //     line: {
    //       fill: ["blue", 'black']
    //     },
    //     text: {
    //       fill: ["red", 'black']
    //     }
    //   },
    //   legend: {
    //     text: {
    //       fill: ["blue", 'black']
    //     }
    //   }
    // },
  })

  useEffect(() => {
    setColor(['#41c38f', '#25bcde', '#4664ff', '#1f2d80', '#ffcc46', '#ff9e45', '#f3546a', '#6C4AB6']);
  }, []);

  useEffect(() => {
    if (props.data.length > 0) {

      let idx = 0;
      let tempState = [];
      for (const item of props.data[0]['data']) {
        if (idx % 5 === 0 || props.data[0].data[0]['x'].indexOf('시') > 0) {
          tempState.push(item['x']);
        }
        idx++;
      }

      setAxisX(tempState);
      setData(props.data);

      if (props.data[0]['id'].indexOf('바리스') >= 0) setUnit('잔');
      if (props.data[0]['id'].indexOf('아리스') >= 0) setUnit('개');
    }
  }, [props.data]);

  return (
    <ResponsiveLine
      data={data}
      // width={props.width || 1000}
      height={props.height || props.width < 768 ? 450 : (props.page === 'HOME' ? 500 : 500)}
      margin={{top: 10, right: props.width < 768 ? 10 : 200, bottom: 180, left: 40}}
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: false,
        reverse: false
      }}
      yFormat={value =>
        `${value.toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${unit}`
      }
      colors={color}
      isInteractive={true}
      lineWidth={5}
      axisTop={null}
      axisRight={null}
      axisBottom={props.type === 'map' ?
        {
          tickSize: 0,
          tickPadding: 10,
          tickRotation: -45,
          legend: '',
          legendOffset: 36,
          legendPosition: 'middle',
        }
        : {
          tickSize: 0,
          tickPadding: 10,
          tickRotation: -45,
          legend: '',
          legendOffset: 36,
          legendPosition: 'middle',
          tickValues: props.page === 'HOME' ? '' : axisX
        }
      }
      // markers={[
      //   {
      //     axis: 'x',
      //     value: value => console.log('00000skljvk') , // Here is the error, unable to translate on x-axis
      //     lineStyle: {
      //       stroke: '#ff0000',
      //       strokeWidth: 2,
      //       opacity: 1,
      //     },
      //     legend: 'x marker',
      //     textStyle: {
      //       fill: '#fff',
      //       fontSize: 15,
      //     },
      //   },
      // ]}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      enablePoints={false}
      pointSize={10}
      pointColor={{theme: 'background'}}
      pointBorderWidth={2}
      pointBorderColor={{from: 'serieColor'}}
      pointLabelYOffset={-12}
      useMesh={props.type !== 'map'}
      legends={[
        {
          anchor: props.width < 768 ? (props.page === 'HOME' ? 'bottom' : 'bottom-left') : 'bottom-right',
          direction: props.width < 768 && props.page === 'HOME' ? 'row' : 'column',
          justify: false,
          translateX: 60,
          translateY: props.width < 768 ? (props.page === 'HOME' ? 70 : 170) : 0,
          itemsSpacing: 0,
          itemDirection: 'left-to-right',
          itemWidth: props.width < 768 ? 100 : 50,
          itemHeight: 17,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: 'circle',
          symbolBorderColor: 'rgba(0, 0, 0, .5)',
        }
      ]}
      // theme={{
      //   axis: {
      //     fontSize: "14px",
      //     tickColor: "#eee",
      //     ticks: {
      //       line: {
      //         fill: "blue"
      //       },
      //       text: {
      //         fill: value => `${value['color']}`
      //       }
      //     },
      //     legend: {
      //       text: {
      //         fill: 'black'
      //       }
      //     }
      //   },
      // }}
      enableSlices={'x'}
    />
  );
};
