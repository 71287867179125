import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";

// CSS
import './MenuMobile.scss';
import NetworkLayout, {COMMAND} from "../../common/Network/NetworkLayout";
import {AXIOS_GET, AXIOS_POST} from "../../common/Network/CommonFunction";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {
  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;
    case COMMAND.DATA_UPDATE : {
      const {requestUrl, sendString} = params;
      response = await AXIOS_POST(requestUrl, JSON.stringify(sendString));
    }
      break;
    default:
      return null;
  }

  return response.data;
}

export interface item {
  setMenu: any;
}


export const MenuMobile: React.FC<item> = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [menuList, setMenuList] = useState([]);

  const imageList = {
    'HOME': '/static/img/icon_home.png',
    'BARIS': '/static/img/icon_cup.png',
    'ARIS': '/static/img/icon_aris.png',
    'ROBOT': '/static/img/icon_baris.png',
    'STORE': '/static/img/icon_mooin_store.png',
    'POINTCARD': '/static/img/icon_point.png',
    'SETTINGS': '/static/img/icon_settings.png',
  }

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback(async (action: any, data: any) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setMenuList(data.payload);
      }
      if (command === COMMAND.DATA_UPDATE) {
        localStorage.setItem("accessToken", data.payload.accessToken);
        window.location.reload();
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/setting/employee/menu'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, []);

  const handleLogout = () => {
    if (window.confirm('로그아웃 하시겠습니까?')) {
      localStorage.removeItem('accessToken');
      navigate('/login');
    }
  };

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'menuMobile'}>
        <div className="logo-layer">
          <div className="logo-icon">
            <img src={'/static/img/menu_icon.png'} alt={'logo'} />
            <div className={'closeBtn'} onClick={() => props.setMenu(false)}>X</div>
          </div>
        </div>

        {(menuList || []).map((item, index) => {
          return (
            item['rights'] === 'R' || item['rights'] === 'W' ?
              <div className={"menu-layer " + (location.pathname === item['path'] ? "on" : "")}
                   onClick={() => {
                     navigate(item['path']);
                     props.setMenu(false);
                   }} key={index}>
                <div className="menu-btn-wrap">
                  <span className="icon-title">{item['name']}</span>
                </div>
              </div>
              : null
          )
        })}

        <div className={"menu-layer" + (location.pathname === "/logout" || location.pathname === "/logout/" ? "on" : "")} onClick={() => handleLogout()}>
          <div className="menu-btn-wrap">
            <div className="icon-title logout" style={{'cursor': 'pointer'}}>LOGOUT</div>
          </div>
        </div>
      </div>
    </>
  );
};
