import React, {useCallback, useEffect, useRef, useState} from "react";

// Components
import {DatePicker} from "./DatePicker";

// css
import './css/SearchBox.scss';

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET} from "../common/Network/CommonFunction";
import {handleDateFormat} from "./common/moment";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_INFO : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const SearchBox = (props: any) => {
  const [period, setPeriod] = useState<string>('month');
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [branch, setBranch] = useState<string>('ALL');
  const [type, setType] = useState<string>('DAY');
  const [category, setCategory] = useState<string>('0');

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_INFO) {
        for (let item of data.payload.line) {
          let idx = 0;
          if (item['data'][0]['x'].length > 3) {
            for (let data of item['data']) {
              if (data['x'].substr(6, 8) !== '01') {
                data['x'] = data['x'].substr(4, 8);
                if (idx % 3 === 0) data['color'] = 'black';
                else data['color'] = 'red';

                idx++;
              }
            }
          } else {
            for (let data of item['data']) {
              data['x'] += ' 시';
            }
          }
        }
        for (let item of data.payload.bar) {
          if (item['date'].length > 3) {
            if (item['date'].substr(6, 8) !== '01') {
              item['date'] = item['date'].substr(4, 8);
            }
          } else {
            item['date'] += ' 시';
          }
        }
        props.setState(data.payload);
        props.setChart(data.payload.line);
        props.setBarChart(data.payload.bar);
        props.setTop(data.payload.top);
      }
    }
  }, [props]);

  useEffect(() => {
    handleSearch();
  }, [period, branch, type, startDate, endDate, category]);

  function useInterval(callback:any, delay:number) {
    const savedCallback = useRef<any>();
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    handleSearch();
  }, 30000);

  const handleSearch = () => {
    let now = new Date();
    let today = new Date();
    let date;

    if (period !== 'self') {
      if (period === 'month') {
        date = new Date(now.setMonth(now.getMonth() - 1));
      } else if (period === 'long') {
        date = new Date(now.setMonth(now.getMonth() - 3));
      }
    } else {
      date = startDate;
      today = endDate;
    }

    let robot = window.location.pathname === '/baris' ? 'baris' : 'aris';

    const command = COMMAND.DATA_INFO;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/stats/' + robot + '?branch_id=' + branch + '&start_date=' + handleDateFormat('date', date) + '&end_date=' + handleDateFormat('date', today) + '&stat_view_type=' + type + '&cate_type=' + category
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }

  const handleDate = (state: string, value: Date) => {
    if (state === 'start') {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  }

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={"searchBoxContainer"}>
        <div className={"searchItem"}>
          {/*<div className={"selectTitle"}>매장</div>*/}
          <select className={"selectBox"} onChange={(e) => setBranch(e.target.value)}>
            <option value={'ALL'} key={'ALL'}>전체</option>
            {(props.storeList || []).map((item: any, index: any) => {
              return (
                <option value={item['branch_id']} key={index}>{item['branch_nm_ko']}</option>
              )
            })}
          </select>
        </div>
        <div className={"searchItem"}>
          {/*<div className={"selectTitle"}>기간</div>*/}
          <div className={"radioBox"}>
            <button type="button" className={period === 'month'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setPeriod('month')}>한달</button>
            <button type="button" className={period === 'long'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setPeriod('long')}>3개월</button>
            <button type="button" className={period === 'self'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setPeriod('self')}>기간설정</button>
            {period === 'self' ?
              <div className={'dateBox'}>
                <DatePicker period={period} date={startDate} state={'start'} handleDate={handleDate}/>
                &nbsp;-&nbsp;
                <DatePicker period={period} date={endDate} state={'end'} handleDate={handleDate}/>
              </div>
              : null}
          </div>
        </div>
        <div className={"searchItem"}>
          {/*<div className={"selectTitle"}>기간</div>*/}
          <div className={"radioBox"}>
            <button type="button" className={type === 'DAY'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setType('DAY')}>DAY</button>
            <button type="button" className={type === 'TIME'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setType('TIME')}>TIME</button>
          </div>
        </div>
        <div className={"searchItem"}>
          {/*<div className={"selectTitle"}>기간</div>*/}
          <div className={"radioBox"}>
            <button type="button" className={category === '0'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setCategory('0')}>전체</button>
            <button type="button" className={category === '1'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setCategory('1')}>음료</button>
            <button type="button" className={category === '2'? 'btn btn-outline-dark on' : "btn btn-outline-dark"} onClick={() => setCategory('2')}>비음료</button>
          </div>
        </div>
      </div>
    </>
  );
}
