import React, {useCallback, useEffect, useRef, useState} from "react";

// CSS
import './index.scss';

// Components
import {SearchBox} from "../components/SearchBox";
import {LineChart} from "../components/LineChart";
import {BarChart} from "../components/BarChart";

// Network
import NetworkLayout, {COMMAND} from "../common/Network/NetworkLayout";
import {AXIOS_GET} from "../common/Network/CommonFunction";
import {handleNumber} from "../components/common/common";

/**
 *  @type      {function(*): *}
 *  @function  back-end 와 통신 하는 비지니스 로직 함수
 *  @param     {String} command  - back-end 처리 요청 구분(목록요청, 데이터 처리 등)
 *  @param     {Object} params   - back-end 처리 요청에 필요한 parameter
 *  @return    {Object} response.data - back-end 응답
 */
async function dataProcess(command: string, params: any): Promise<any> {

  let response = null;

  switch (command) {
    // 데이터 목록 정보 요청
    case COMMAND.DATA_LIST : {
      const {requestUrl} = params;
      response = await AXIOS_GET(requestUrl);
    }
      break;

    default:
      return null;
  }

  return response.data;
}

export const Aris: React.FC = (props: any) => {
  const [storeList, setStoreList] = useState<string[]>([]);
  const [tab, setTab] = useState<number>(0);
  const [chartType, setChartType] = useState<string>('line');
  const [detail, setDetail] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [chartBarData, setChartBarData] = useState<any>([]);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [top, setTop] = useState<any>([]);

  /**
   *   상위 컴포넌트에서 전달 받은 props
   *   1. history    : URL 이동을 위해 사용
   */
  const {history} = props;

  /** 통신 관련된 처리를 해주는 agent 컴포넌트 */
  const netWorkAgent = useRef<any>(null);

  /**
   *  @function  dataResponse
   *  @param  {Object} action - 요청시 보낸 정보(command, params)
   *  @param  {Object} data   - 검색어
   *  @description  back-end 로 부터 응답 데이터가 왔을 때 처리 부분
   */
  const dataResponse = useCallback((action: any, data: any) => {
    // data 가 있는 경우
    if (data) {
      // action state 에서 이전 호출했던 정보 get
      const {command} = action;

      // 응답에 대한 처리
      if (command === COMMAND.DATA_LIST) {
        setStoreList(data.payload.branch_list)
      }
    }
  }, []);

  /** back end 통신 example  */
  useEffect(() => {
    const command = COMMAND.DATA_LIST;
    const params = {
      requestUrl: process.env.REACT_APP_API_HOST + '/branches/active?robot_class=2'
    };

    /** back-end 데이터 처리 요청 */
    netWorkAgent.current.request(command, params);
  }, [])

  const handleSelectedStore = (data: string) => {
    console.log(data)
  }

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  return (
    <>
      <NetworkLayout ref={netWorkAgent} process={dataProcess} response={dataResponse} history={history}/>
      <div className={'risContainer'}>
        <div className={'searchBox'}>
          <img className={'logoImg'} src="/static/img/maps/logo_aris@3x.png" alt=""/>
          <div className={'searchTitle'}>아리스 데이터</div>
          <SearchBox id={'baris'} storeList={storeList} handleSelectedStore={handleSelectedStore} setState={setDetail}
                     setChart={setChartData} setBarChart={setChartBarData} setTop={setTop}/>
        </div>
        <div className={'overview'}>
          <div className={'itemBox'} style={{'backgroundColor': 'rgba(255, 204, 70, 0.2)'}}>
            <div className={'itemLeft'}>
              <img src={'/static/img/dashboard/ic_time@2x.png'} alt={'icon_baris'}/>
            </div>
            <div className={'itemRight'}>
              <div className={'itemTitle'}>아리스가 일한 시간</div>
              <div className={'itemContent'}>{<div>
                <span>{handleNumber(Math.floor(Number(detail['work_time'] / 60 || 0)))}</span>h <span>{handleNumber(Math.floor(Number(detail['work_time'] % 60 || 0)))}</span>m
              </div>}</div>
            </div>
          </div>
          <div className={'itemBox'} style={{'backgroundColor': 'rgba(255, 204, 70, 0.2)'}}>
            <div className={'itemLeft'}>
              <img src={'/static/img/dashboard/ic_icecream@3x.png'} alt={'icon_baris'}/>
            </div>
            <div className={'itemRight'}>
              <div className={'itemTitle'}>평균 제조량</div>
              <div className={'itemContent'}>{<div>
                <span>{handleNumber(Math.floor(Number(detail['avg_qty'] || 0)))} 개</span></div>}</div>
            </div>
            <div className={'divide'}></div>
            <div className={'itemRight'}>
              <div className={'itemTitle'}>누적 제조량</div>
              <div className={'itemContent'}>{<div>
                <span>{handleNumber(Math.floor(Number(detail['sum_qty'] || 0)))} 개</span></div>}</div>
            </div>
          </div>

          {/*{top.length > 0 ?*/}
          {/*  <div className={'itemBox'}>*/}
          {/*    <div className={'itemTop'}>음료 TOP3</div>*/}
          {/*    {(top || []).map((item: any, index: number) => {*/}
          {/*      return (*/}
          {/*        <>*/}
          {/*          <div className={'itemRight'} key={index}>*/}
          {/*            <div className={'topTitle'}>{Math.floor(item['percent'])} %</div>*/}
          {/*            <div className={'topContent'}>{item['prod_nm_ko']}</div>*/}
          {/*          </div>*/}
          {/*          {top.length === index + 1 ? '' : <div className={'divide'}></div>}*/}
          {/*        </>*/}
          {/*      )*/}
          {/*    })}*/}
          {/*  </div> : null}*/}

        </div>
        <div className={'overview'}>
          <div className={'overviewTab'}>
            <div className={tab === 0 ? 'tab on' : 'tab'} onClick={() => setTab(0)}>로봇 제조량</div>
            <div className={'chartIconContainer'}>
              <div className={'chart_icon'} onClick={() => setChartType('line')}>
                {chartType === 'line' ? <img src="/static/img/dashboard/linegraph_on.png" alt=""/> :
                  <img src="/static/img/dashboard/linegraph_off.png" alt=""/>}
              </div>
              <div className={'chart_icon'} onClick={() => setChartType('bar')}>
                {chartType === 'bar' ? <img src="/static/img/dashboard/bargraph_on.png" alt=""/> :
                  <img src="/static/img/dashboard/bargraph_off.png" alt=""/>}
              </div>
            </div>
          </div>
          <div className={'overViewContent'}>
            {chartType === 'line' ? <LineChart data={chartData} width={windowWidth < 768 ? 500 : 1000}/> :
              <BarChart data={chartBarData} width={windowWidth < 768 ? 500 : 1000}/>}
          </div>
        </div>
      </div>
    </>
  );
};
